/* XL Device :1200px. */
/* autoprefixer: off */
@media (min-width: 1200px) and (max-width: 1449px) {
  .view__lf--bar-menu {
    padding-left: 40px;
  }

  .view-link {
    width: 120px;
    height: 60px;
    font-size: 16px;
  }

  .place__ri {
    width: 66%;
    background: #ffffff;
    padding: 20px 11px;
    height: 100%;
    border-radius: 20px 15px 15px 20px;
  }
  .place__lf {
    width: 32%;
    padding: 15px;
    height: 100%;
  }
  .place__pond__single--box {
    height: 70px;
    width: 100%;
    padding: 0 5px;
    border-radius: 16px;
  }
  .test__bottom--imp input {
    width: 70%;
  }
  .test__bottom--imp button {
    width: 27%;
  }

  .progress {
    width: 92px;
    height: 8px;
    background: #ededed;
    position: relative;
  }
  .sidebar {
    width: 22%;
  }
  .main-content {
    width: 78%;
    margin-left: 22%;
  }
  .info-area {
    padding-left: 0;
  }
  .info-check-list {
    width: 30%;
  }
  .reword-img {
    width: 80px;
    height: 80px;
    margin-right: 14px;
  }

  .bat-title {
    margin-bottom: 21px;
  }
  .intro-top-text p {
    font-size: 14px;
  }
  .treck-right {
    width: 65%;
  }
  .track-left {
    width: 35%;
  }
  .track-ri-top p {
    font-size: 14px;
  }
  .Conclusion-area p {
    font-size: 14px;
  }
  .dash-tab-left {
    width: 67%;
  }
  .dash-tab-right {
    width: 30%;
  }

  /* sohan--responsive */

  .view__lf--bar-menu {
    padding-left: 20px;
  }
  .sign__btn {
    margin-top: 140px;
    padding-left: 20px;
  }
  .view-link {
    width: 120px;
    height: 60px;
    font-size: 16px;
  }

  .place__ri {
    width: 66%;
    background: #ffffff;
    padding: 20px 11px;
    height: 100%;
    border-radius: 20px 15px 15px 20px;
  }
  .place__lf {
    width: 32%;
    padding: 15px;
    height: 100%;
  }
  .place__pond__single--box {
    height: 70px;
    width: 100%;
    padding: 0 5px;
    border-radius: 16px;
  }
  .test__bottom--imp input {
    width: 70%;
  }
  .test__bottom--imp button {
    width: 27%;
  }
  .progress {
    width: 92px;
    height: 8px;
    background: #ededed;
    position: relative;
  }

  .view__header__lf--bar {
    width: 22%;
  }
  .view__page--body {
    width: 78%;
    margin-left: 22%;
  }
  .intro__content {
    margin-left: 20px;
  }
}

/* LG Device :992px. */
@media (min-width: 992px) and (max-width: 1200px) {
  .dash-popular-area {
    grid-template-columns: 48% 48%;
  }

  .view__lf--bar-menu {
    padding-left: 40px;
  }

  .view-link {
    width: 120px;
    height: 60px;
    font-size: 16px;
  }
  .view__header__lf--bar {
    width: 320px;
    height: 100%;
    position: fixed;
    left: -100%;
    top: 0;
    transition: 0.3s;
    z-index: 999999999;
    background: #f7f8fa;
  }
  .view__page--body {
    width: 100%;
    margin-left: 0;
  }
  .intro__content {
    width: 400px;
    margin-left: 20px;
  }
  .botanic__title h3 {
    font-size: 30px;
    line-height: 43px;
  }

  .place__ri {
    width: 66%;
    background: #ffffff;
    padding: 20px 11px;
    height: 100%;
    border-radius: 20px 15px 15px 20px;
  }
  .place__lf {
    width: 32%;
    padding: 15px;
    height: 100%;
  }
  .place__pond__single--box {
    height: 70px;
    width: 100%;
    padding: 0 13px;
    border-radius: 16px;
  }
  .botanic__area {
    padding-left: 20px;
    padding-right: 20px;
    background: #ffffff;
    padding-top: 40px;
  }

  .progress {
    width: 92px;
    height: 8px;
    background: #ededed;
    position: relative;
  }
  .test__bottom--imp input {
    width: 70%;
  }
  .test__bottom--imp button {
    width: 27%;
  }

  .header___bar {
    display: block;
  }
  .view__header__menu {
    height: 80px;
    display: flex;
    align-items: center;
    background: #fff;
    padding-left: 32px;
    justify-content: space-between;
    padding-right: 32px;
  }
  .sidebar {
    width: 28%;
    left: -100%;
  }
  .main-content {
    width: 100%;
    margin-left: 0;
  }
  .info-area {
    padding-left: 0;
  }
  .info-check-list {
    width: 30%;
  }

  .reword-img {
    width: 80px;
    height: 80px;
    margin-right: 14px;
  }
  .bat-title {
    margin-bottom: 21px;
  }
  .intro-top-text p {
    font-size: 14px;
  }
  .treck-right {
    width: 65%;
  }
  .track-left {
    width: 35%;
  }
  .track-ri-top p {
    font-size: 14px;
  }
  .Conclusion-area p {
    font-size: 14px;
  }
  .dash-tab-left {
    width: 67%;
  }
  .dash-tab-right {
    width: 30%;
  }

  .view__lf--bar-menu {
    padding-left: 40px;
  }

  .view-link {
    width: 120px;
    height: 60px;
    font-size: 16px;
  }
  .view__header__lf--bar {
    width: 320px;
    height: 100%;
    position: fixed;
    left: -100%;
    top: 0;
    transition: 0.3s;
    z-index: 999999999;
    background: #f7f8fa;
  }
  .view__page--body {
    width: 100%;
  }
  .intro__content {
    width: 400px;
    margin-left: 20px;
  }
  .botanic__title h3 {
    font-size: 30px;
    line-height: 43px;
  }
  .sign__btn {
    margin-top: 140px;
    padding-left: 20px;
  }

  .place__ri {
    width: 66%;
    background: #ffffff;
    padding: 20px 11px;
    height: 100%;
    border-radius: 20px 15px 15px 20px;
  }
  .place__lf {
    width: 32%;
    padding: 15px;
    height: 100%;
  }
  .place__pond__single--box {
    height: 70px;
    width: 100%;
    padding: 0 13px;
    border-radius: 16px;
  }
  .botanic__area {
    padding-left: 20px;
    padding-right: 20px;
    background: #ffffff;
    padding-top: 40px;
  }

  .progress {
    width: 92px;
    height: 8px;
    background: #ededed;
    position: relative;
  }

  .test__bottom--imp input {
    width: 70%;
  }
  .test__bottom--imp button {
    width: 27%;
  }

  .header___bar {
    display: block;
  }
  .view__header__menu {
    height: 80px;
    display: flex;
    align-items: center;
    background: #fff;
    padding-left: 32px;
    justify-content: space-between;
    padding-right: 32px;
  }
  .sidebar.active {
    left: 0;
    z-index: 9;
  }
}

/* MD Device :768px. */
@media (min-width: 768px) and (max-width: 991px) {
  .view__lf--bar-menu {
    padding-left: 40px;
  }

  .view-link {
    width: 120px;
    height: 60px;
    font-size: 16px;
  }
  .view__header__lf--bar {
    width: 320px;
    height: 100%;
    position: fixed;
    left: -100%;
    top: 0;
    transition: 0.3s;
    z-index: 999999999;
    background: #f7f8fa;
  }
  .view__page--body {
    width: 100%;
  }
  .intro__content {
    width: 400px;
    margin-left: 20px;
  }
  .botanic__title h3 {
    font-size: 25px;
    line-height: 43px;
  }
  .view__task__wrap {
    margin-top: 20px;
  }
  .botanic__area {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 30px;
  }
  .botanic__button .btn {
    width: 125px;
    height: 40px;
    font-size: 14px;
  }
  .botanic__top {
    margin-bottom: 5px;
  }

  .user__fx {
    display: block;
  }
  .user__lf {
    width: 100%;
  }
  .view-tabs {
    border-bottom: 1px solid #f1f1f1;
    margin-bottom: 20px;
  }
  .intro__fx {
    padding-bottom: 10px;
    margin-bottom: 30px;
  }
  .comnc__box {
    max-width: 680px;
    padding-left: 0;
    padding-bottom: 80px;
  }
  .comnc__box {
    max-width: 680px;
    padding-left: 0;
    padding-bottom: 40px;
  }
  .user__ri {
    width: 71%;
    margin: auto;
  }
  .botanic__star {
    margin-bottom: 19px;
  }
  .place__ri {
    width: 66%;
    background: #ffffff;
    padding: 20px 11px;
    height: 100%;
    border-radius: 20px 15px 15px 20px;
  }
  .place__lf {
    width: 32%;
    padding: 15px;
    height: 100%;
  }
  .place__pond__single--box {
    height: 70px;
    width: 100%;
    padding: 0 13px;
    border-radius: 16px;
  }
  .modal-dialog {
    max-width: 720px;
  }

  .single--check.check-sec {
    width: 430px;
    margin-left: auto;
    margin-bottom: 80px;
  }

  .single--check.nurom {
    width: 402px;
    margin-left: auto;
  }
  .place__add-tx {
    max-width: 400px;
    margin-left: auto;
  }
  .place__single---input.short--check label {
    width: 433px;
  }
  .header___bar {
    display: block;
  }

  .view__header__menu {
    height: 80px;
    display: flex;
    align-items: center;
    background: #fff;
    padding-left: 32px;
    justify-content: space-between;
    padding-right: 32px;
  }
  .sidebar {
    width: 36%;
    left: -100%;
  }
  .main-content {
    width: 100%;
    margin-left: 0;
  }
  .info-area {
    padding-left: 0;
    flex-wrap: wrap;
    height: auto !important;
  }
  .info-check-list {
    width: 100%;
    margin-bottom: 30px;
  }

  .info-containt {
    width: 100%;
    order: 2;
  }
  .reword-img {
    width: 80px;
    height: 80px;
    margin-right: 14px;
  }

  .bat-title {
    margin-bottom: 21px;
  }
  .intro-top-text p {
    font-size: 14px;
  }
  .treck-right {
    width: 65%;
  }
  .track-left {
    width: 35%;
  }
  .track-ri-top p {
    font-size: 14px;
  }
  .Conclusion-area p {
    font-size: 14px;
  }
  .dash-tab-left {
    width: 100%;
  }
  .dash-tab-right {
    width: 100%;
  }

  .dash-tab-create {
    flex-wrap: wrap;
  }
  .dash-tab-left {
    width: 100%;
  }
  .popular-text h3 {
    font-size: 16px;
  }
  .dash-tab-right {
    width: 100%;
  }

  .view__lf--bar-menu {
    padding-left: 40px;
  }

  .view-link {
    width: 120px;
    height: 60px;
    font-size: 16px;
  }
  .view__header__lf--bar {
    width: 320px;
    height: 100%;
    position: fixed;
    left: -100%;
    top: 0;
    transition: 0.3s;
    z-index: 999999999;
    background: #f7f8fa;
  }
  .view__page--body {
    width: 100%;
    margin-left: 0;
  }
  .intro__content {
    width: 400px;
    margin-left: 20px;
  }
  .sign__btn {
    margin-top: 140px;
    padding-left: 20px;
  }
  .botanic__title h3 {
    font-size: 25px;
    line-height: 43px;
  }
  .view__task__wrap {
    margin-top: 20px;
  }
  .botanic__area {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 30px;
  }
  .botanic__button .btn {
    width: 125px;
    height: 40px;
    font-size: 14px;
  }
  .botanic__top {
    margin-bottom: 5px;
  }

  .user__fx {
    display: block;
  }
  .user__lf {
    width: 100%;
  }
  .view-tabs {
    border-bottom: 1px solid #f1f1f1;
    margin-bottom: 20px;
  }
  .intro__fx {
    padding-bottom: 10px;
    margin-bottom: 30px;
  }
  .comnc__box {
    max-width: 680px;
    padding-left: 0;
    padding-bottom: 80px;
  }
  .comnc__box {
    max-width: 680px;
    padding-left: 0;
    padding-bottom: 40px;
  }
  .user__ri {
    width: 71%;
    margin: auto;
  }
  .botanic__star {
    margin-bottom: 19px;
  }

  .place__ri {
    width: 66%;
    background: #ffffff;
    padding: 20px 11px;
    height: 100%;
    border-radius: 20px 15px 15px 20px;
  }
  .place__lf {
    width: 32%;
    padding: 15px;
    height: 100%;
  }
  .place__pond__single--box {
    height: 70px;
    width: 100%;
    padding: 0 13px;
    border-radius: 16px;
  }
  .modal-dialog {
    max-width: 720px;
  }

  .single--check.check-sec {
    width: 430px;
    margin-left: auto;
    margin-bottom: 80px;
  }

  .single--check.nurom {
    width: 402px;
    margin-left: auto;
  }
  .place__add-tx {
    max-width: 400px;
    margin-left: auto;
  }
  .place__single---input.short--check label {
    width: 433px;
  }

  .header___bar {
    display: block;
  }

  .view__header__menu {
    height: 80px;
    display: flex;
    align-items: center;
    background: #fff;
    padding-left: 32px;
    justify-content: space-between;
    padding-right: 32px;
  }

  .sidebar.active {
    left: 0;
    z-index: 9;
  }
}

/* SM Small Device :320px. */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .dash-popular-area {
    grid-template-columns: 100%;
  }
  .view__lf--bar-menu {
    padding-left: 40px;
  }

  .view-link {
    width: 100%;
    height: 60px;
    font-size: 16px;
  }
  .view-item {
    width: 48%;
  }
  .view__header__lf--bar {
    width: 320px;
    height: 100%;
    position: fixed;
    left: -100%;
    top: 0;
    transition: 0.3s;
    z-index: 999999999;
    background: #f7f8fa;
  }
  .view__page--body {
    width: 100%;
  }
  .intro__content {
    width: 100%;
    margin-left: 0;
  }
  .botanic__title h3 {
    font-size: 25px;
    line-height: 43px;
    margin-bottom: 7px;
  }
  .botanic__area {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 30px;
  }
  .botanic__button .btn {
    width: 125px;
    height: 40px;
    font-size: 11px;
  }
  .botanic__top {
    margin-bottom: 5px;
    display: block;
    text-align: center;
  }

  .user__fx {
    display: block;
  }
  .user__lf {
    width: 100%;
  }
  .view-tabs {
    border-bottom: 1px solid #f1f1f1;
    margin-bottom: 20px;
  }
  .intro__fx {
    padding-bottom: 10px;
    margin-bottom: 30px;
    display: block;
  }
  .comnc__box {
    max-width: 680px;
    padding-left: 0;
    padding-bottom: 80px;
  }
  .comnc__box {
    max-width: 680px;
    padding-left: 0;
    padding-bottom: 40px;
  }
  .user__ri {
    width: 100%;
    margin: auto;
  }
  .botanic__star {
    margin-bottom: 19px;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .botanic__button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .view__header__menu ul li a {
    font-size: 13px;
    line-height: 24px;
  }
  .view__header__menu ul li {
    display: inline-block;
    margin-right: 6px;
  }
  .view__header__menu {
    height: 60px;
    display: flex;
    align-items: center;
    background: #fff;
    padding-left: 13px;
    justify-content: space-between;
    padding-right: 15px;
  }
  .check__single--item {
    width: 32%;
  }
  .user--tab--check {
    justify-content: space-between;
  }
  .user--tab--check {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .reward__box {
    width: 100%;
    height: 100px;
    padding: 20px 25px;
    border-radius: 15px;
  }
  .highlight__menu ul li {
    font-size: 13px;
    line-height: 22px;
    padding-left: 20px;
  }
  .Highlights__wrap {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  .intro__img {
    width: 100%;
    margin-bottom: 20px;
  }
  .lee--img {
    width: 60px;
    height: 60px;
    margin-right: 13px;
  }

  .place__ri {
    width: 100%;
    background: #ffffff;
    padding: 20px 11px;
    height: 100%;
    border-radius: 20px 15px 15px 20px;
  }
  .place__lf {
    width: 100%;
    padding: 15px;
    height: 100%;
  }
  .place__pond__single--box {
    height: auto;
    width: 100%;
    padding: 10px 13px;
    border-radius: 16px;
    display: block;
  }
  .place__top__task {
    display: block;
    margin-bottom: 25px;
  }

  .place__top--title h4 {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 10px;
  }
  .place__menu ul li {
    margin-left: 0;
    display: inline-block;
    margin-right: 10px;
  }
  .place__box {
    border: 1px solid #ededed;
    padding: 0;
    border-radius: 15px;
    background: #f7f8fa;
    display: block;
    align-items: start;
    justify-content: space-between;
    height: auto;
    margin-bottom: 20px;
  }
  .chupit__img {
    width: 60px;
    height: 60px;
    margin-right: 8px;
  }

  .progress {
    width: 92px;
    height: 8px;
    background: #ededed;
    position: relative;
  }

  .place__table {
    width: 640px;
  }
  .place__table--wd {
    width: 100%;
    overflow-y: scroll;
  }
  .table__result {
    display: block;
    margin-top: 20px;
  }
  .result_lf {
    text-align: center;
    margin-bottom: 10px;
  }
  .result__number ul {
    justify-content: center;
    margin-bottom: 20px;
  }
  .review__star--top--fx {
    display: block;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
  }
  .review__rating {
    margin-bottom: 25px;
  }
  .review__progress {
    width: 100%;
  }
  .prg {
    width: 229px;
    height: 8px;
    background: #ededed;
    border-radius: 10px;
    position: relative;
  }
  .table__result.result--last {
    padding-bottom: 30px;
  }
  .modal-dialog {
    max-width: 300px;
    margin: auto;
  }
  .place__upload--box {
    padding: 0 20px;
    padding-top: 40px;
  }
  .place__upload--main {
    display: block;
    margin-bottom: 30px;
  }
  .upload {
    width: 100%;
    height: 200px;
    border: 1px solid #2c7d92;
    border-style: dashed;
    border-radius: 16px;
    background: #f7f8fa;
    margin-top: 10px;
  }
  .place__single---input {
    display: block;
  }
  .place__select .nice-select {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }
  .place__single---input label {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
  .place__single---input input {
    width: 100%;
    height: 40px;
    border-radius: 59px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    padding: 0 16px;
  }
  .place__single---input textarea {
    width: 100%;
    height: 160px;
  }
  .place__single---input.place-wd input {
    width: 100%;
    margin-left: 20px;
  }
  .place__single---input.place-wd input {
    width: 100%;
    margin-left: 0;
  }
  .place__single---input {
    margin-bottom: 23px;
  }
  .single--check label {
    width: 500px;
    color: #262626;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }
  .place__select {
    width: 100%;
    display: flex;
  }
  .location--fx {
    width: 100%;
    display: block;
    align-items: center;
    justify-content: space-between;
  }
  .location--single {
    margin-bottom: 15px;
  }
  #test {
    width: 100%;
    height: 300px;
  }

  .cancel {
    width: 160px;
    height: 45px;
    border: 1px solid #f15a5b;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 54px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #f15a5b;
    text-decoration: none;
  }
  .place__pls--fx {
    display: block;
    align-items: center;
  }
  .plus {
    width: 40px;
    height: 40px;
    background: #2c7d92;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #fff;
    margin: auto;
    margin-top: 15px;
  }
  .short__answer--wrap {
    width: 100%;
    background: #f7f8fa;
    padding: 15px;
    border-radius: 16px;
  }
  .polling__inp {
    display: block;
    align-items: start;
    justify-content: space-between;
  }

  .polling__label {
    width: 100%;
    margin-bottom: 20px;
  }
  .polling__imp--wp {
    width: 100%;
  }
  .polling__single--imp input {
    height: 40px;
    padding: 0 16px;
    font-size: 13px;
  }

  .short__fx {
    width: auto;
    background: #fff;
    height: auto;
    padding: 11px 20px;
    border-radius: 20px;
    margin: 0 8px;
  }
  .short__fx {
    width: auto;
    background: #fff;
    height: auto;
    padding: 7px;
    border-radius: 20px;
    margin: 0 8px;
  }
  .scan__fx {
    display: block;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .scan__fx .short__answer--title {
    margin-bottom: 10px;
  }

  .augmented__inp input {
    width: 100%;
    height: 50px;
    border: 1px solid #ededed;
    padding: 0 20px;
    border-radius: 39px;
    margin: auto;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #7d7d7d;
  }
  .augmented.up--wd {
    width: 100%;
    display: flex;
    justify-content: start;
  }

  .test__bottom--imp {
    display: block;
  }
  .test__bottom--imp input {
    width: 100%;
    height: 45px;
    border-radius: 32px;
    padding: 0 15px;
    font-size: 12px;
  }
  .test__bottom--imp button {
    width: 162px;
    height: 45px;
    font-size: 14px;
    line-height: 21px;
    margin-top: 10px;
  }

  .test__btm p {
    font-size: 12px;
  }
  .reject__tx p {
    font-size: 12px;
    color: #262626;
    line-height: 22px;
    font-weight: 400;
    color: #262626;
    margin: 0;
  }
  .view__task__wrap {
    margin-top: 20px;
  }
  .view__task__sm--img {
    width: 80px;
    height: 80px;
    margin-right: 20px;
  }

  .view__task__ck ul li {
    margin-right: 16px;
  }
  .view__task__ck {
    margin-bottom: 20px;
  }
  .short__ans h4 {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 9px;
  }

  .hints_single--item p {
    margin: 0;
    font-size: 11px;
    line-height: 21px;
  }
  .hints_single--item span {
    font-size: 25px;
    font-weight: 700;
    line-height: 50px;
    top: 8px;
    left: 15px;
  }
  .rewards__img {
    width: 60px;
    height: 60px;
  }
  .reward__ct p {
    margin: 0;
    font-family: Poppins;
    font-size: 14px;
    line-height: 27px;
    color: #262626;
  }
  .hints__wrap.re--sec {
    padding-bottom: 40px;
  }
  .task__list {
    width: 100%;
    background: #f7f8fa;
    border-radius: 16px;
    padding: 24px;
    margin-bottom: 40px;
  }
  .add__hint__fx {
    display: block;
    align-items: start;
    margin-top: 20px;
  }
  .add__hint--label label {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .add__hint__inner {
    width: 100%;
  }
  .up--wp {
    display: block;
    align-items: center;
    padding-bottom: 41px;
  }
  .up--wp a {
    margin: auto;
    margin-bottom: 5px;
  }
  .upload__ig {
    width: 100%;
  }

  .header___bar {
    display: block;
  }

  .sidebar {
    width: 53%;
    left: -100%;
  }
  .main-content {
    width: 100%;
    margin-left: 0;
  }
  .info-area {
    padding-left: 0;
    flex-wrap: wrap;
    height: auto !important;
  }
  .info-check-list {
    width: 100%;
    margin-bottom: 30px;
  }

  .info-containt {
    width: 100%;
    order: 2;
  }
  .reword-img {
    width: 60px;
    height: 60px;
    margin-right: 10px;
  }

  .single-info {
    flex-wrap: wrap;
  }
  .single-info label {
    display: block;
    width: 100%;
    margin-top: 0;
    margin-bottom: 6px;
  }
  .single-info .single-input {
    width: 100%;
  }
  .reword-fl {
    width: 100%;
  }
  .single-info.wd p {
    margin-left: 0;
  }
  .single-check-fl {
    width: 100%;
    flex-wrap: wrap;
  }
  .single-inputes {
    width: 100%;
  }
  .form-single-btn a {
    margin-right: 0;
  }

  .single-blocks {
    width: 100%;
  }

  .bat-title {
    margin-bottom: 21px;
  }
  .intro-top-text p {
    font-size: 14px;
  }
  .treck-right {
    width: 100%;
  }
  .track-left {
    width: 76%;
    margin: auto;
  }
  .track-ri-top p {
    font-size: 14px;
  }
  .Conclusion-area p {
    font-size: 14px;
  }

  .bat-title h3 {
    font-size: 20px;
  }
  .bat-title a {
    font-size: 13px;
  }
  .profile-img {
    width: 80px !important;
    right: 40px;
    bottom: -40px;
  }

  .bat-info-fl {
    flex-wrap: wrap;
  }
  .bat-single-info {
    width: 50%;
    margin-bottom: 30px;
  }
  .intro-area {
    flex-wrap: wrap;
  }
  .intro-img {
    width: 100%;
    margin-bottom: 30px;
  }
  .intro-img img {
    width: 100%;
  }
  .intro-containt {
    width: 100%;
  }
  .track-area {
    flex-wrap: wrap;
  }

  .singin-box.hig {
    padding: 40px 30px;
    height: auto;
  }
  .singin-btn button {
    width: 100%;
  }

  .singin-text.pd {
    padding-top: 50px;
  }

  .dash-tab-left {
    width: 100%;
  }
  .dash-tab-right {
    width: 100%;
  }

  .dash-tab-create {
    flex-wrap: wrap;
  }
  .dash-tab-left {
    width: 100%;
  }
  .popular-text h3 {
    font-size: 16px;
  }
  .dash-tab-right {
    width: 100%;
  }

  .dash-tab {
    flex-wrap: wrap;
  }
  .dash-tab ul li a {
    font-size: 14px !important;
    width: 150px;
  }
  .dash-tab ul {
    margin-bottom: 20px !important;
  }
  .dash-create-btn {
    width: 100%;
  }
  .dash-create-btn a {
    width: 100%;
  }
  .singin-area {
    padding: 30px 0;
  }

  .singin-box {
    padding: 40px 30px;
  }
  .verify-box-last-text {
    padding-top: 60px;
  }

  .view__lf--bar-menu {
    padding-left: 40px;
  }

  .view-link {
    width: 100%;
    height: 60px;
    font-size: 16px;
  }
  .view-item {
    width: 48%;
  }
  .view__header__lf--bar {
    width: 320px;
    height: 100%;
    position: fixed;
    left: -100%;
    top: 0;
    transition: 0.3s;
    z-index: 999999999;
    background: #f7f8fa;
  }
  .view__page--body {
    width: 100%;
    margin-left: 0;
  }
  .intro__content {
    width: 100%;
    margin-left: 0;
  }
  .sign__btn {
    margin-top: 140px;
    padding-left: 20px;
  }
  .botanic__title h3 {
    font-size: 25px;
    line-height: 43px;
    margin-bottom: 7px;
  }
  .botanic__area {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 30px;
  }
  .botanic__button .btn {
    width: 125px;
    height: 40px;
    font-size: 11px;
  }
  .botanic__top {
    margin-bottom: 5px;
    display: block;
    text-align: center;
  }

  .user__fx {
    display: block;
  }
  .user__lf {
    width: 100%;
  }
  .view-tabs {
    border-bottom: 1px solid #f1f1f1;
    margin-bottom: 20px;
  }
  .intro__fx {
    padding-bottom: 10px;
    margin-bottom: 30px;
    display: block;
  }
  .comnc__box {
    max-width: 680px;
    padding-left: 0;
    padding-bottom: 80px;
  }
  .comnc__box {
    max-width: 680px;
    padding-left: 0;
    padding-bottom: 40px;
  }
  .user__ri {
    width: 100%;
    margin: auto;
  }
  .botanic__star {
    margin-bottom: 19px;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .botanic__button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .view__header__menu ul li a {
    font-size: 13px;
    line-height: 24px;
  }
  .view__header__menu ul li {
    display: inline-block;
    margin-right: 6px;
  }
  .view__header__menu {
    height: 60px;
    display: flex;
    align-items: center;
    background: #fff;
    padding-left: 13px;
    justify-content: space-between;
    padding-right: 15px;
  }
  .check__single--item {
    width: 32%;
  }
  .user--tab--check {
    justify-content: space-between;
  }
  .user--tab--check {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .reward__box {
    width: 100%;
    height: 100px;
    padding: 20px 25px;
    border-radius: 15px;
  }
  .highlight__menu ul li {
    font-size: 13px;
    line-height: 22px;
    padding-left: 20px;
  }
  .Highlights__wrap {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  .intro__img {
    width: 100%;
    margin-bottom: 20px;
  }
  .lee--img {
    width: 60px;
    height: 60px;
    margin-right: 13px;
  }

  .place__ri {
    width: 100%;
    background: #ffffff;
    padding: 20px 11px;
    height: 100%;
    border-radius: 20px 15px 15px 20px;
  }
  .place__lf {
    width: 100%;
    padding: 15px;
    height: 100%;
  }
  .place__pond__single--box {
    height: auto;
    width: 100%;
    padding: 10px 13px;
    border-radius: 16px;
    display: block;
  }
  .place__top__task {
    display: block;
    margin-bottom: 25px;
  }

  .place__top--title h4 {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 10px;
  }
  .place__menu ul li {
    margin-left: 0;
    display: inline-block;
    margin-right: 10px;
  }
  .place__box {
    border: 1px solid #ededed;
    padding: 0;
    border-radius: 15px;
    background: #f7f8fa;
    display: block;
    align-items: start;
    justify-content: space-between;
    height: auto;
    margin-bottom: 20px;
  }
  .chupit__img {
    width: 60px;
    height: 60px;
    margin-right: 8px;
  }

  .progress {
    width: 92px;
    height: 8px;
    background: #ededed;
    position: relative;
  }

  .place__table {
    width: 640px;
  }
  .place__table--wd {
    width: 100%;
    overflow-y: scroll;
  }
  .table__result {
    display: block;
    margin-top: 20px;
  }
  .result_lf {
    text-align: center;
    margin-bottom: 10px;
  }
  .result__number ul {
    justify-content: center;
    margin-bottom: 20px;
  }
  .review__star--top--fx {
    display: block;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
  }
  .review__rating {
    margin-bottom: 25px;
  }
  .review__progress {
    width: 100%;
  }
  .prg {
    width: 229px;
    height: 8px;
    background: #ededed;
    border-radius: 10px;
    position: relative;
  }
  .table__result.result--last {
    padding-bottom: 30px;
  }
  .modal-dialog {
    max-width: 352px;
    margin: auto;
  }
  .place__upload--box {
    padding: 0 20px;
    padding-top: 40px;
  }
  .place__upload--main {
    width: 100%;
    display: block;
    margin-bottom: 30px;
  }
  .place-up-box {
    padding: 10px;
  }
  .up-box-top a {
    font-size: 14px;
  }
  .up-box-top-right span {
    font-size: 11px;
  }
  .up-box-top a {
    font-size: 12px;
  }
  .up-box p {
    font-size: 12px;
  }
  .upload {
    width: 100%;
    height: 200px;
    border: 1px solid #2c7d92;
    border-style: dashed;
    border-radius: 16px;
    background: #f7f8fa;
    margin-top: 10px;
  }
  .place__single---input {
    display: block;
  }
  .place__select .nice-select {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }
  .place__single---input label {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
  .place__single---input input {
    width: 100%;
    height: 40px;
    border-radius: 59px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    padding: 0 16px;
  }
  .place__single---input textarea {
    width: 100%;
    height: 160px;
  }
  .place__single---input.place-wd input {
    width: 100%;
    margin-left: 20px;
  }
  .place__single---input.place-wd input {
    width: 100%;
    margin-left: 0;
  }
  .place__single---input {
    margin-bottom: 23px;
  }
  .single--check label {
    width: 500px;
    color: #262626;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }
  .place__select {
    width: 100%;
    display: flex;
  }
  .location--fx {
    width: 100%;
    display: block;
    align-items: center;
    justify-content: space-between;
  }

  #test {
    width: 100%;
    height: 300px;
  }

  .cancel {
    width: 160px;
    height: 45px;
    border: 1px solid #f15a5b;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 54px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #f15a5b;
    text-decoration: none;
  }
  .place__pls--fx {
    display: block;
    align-items: center;
  }
  .plus {
    width: 40px;
    height: 40px;
    background: #2c7d92;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #fff;
    margin: auto;
    margin-top: 15px;
  }
  .short__answer--wrap {
    width: 100%;
    background: #f7f8fa;
    padding: 15px;
    border-radius: 16px;
  }
  .polling__inp {
    display: block;
    align-items: start;
    justify-content: space-between;
  }

  .polling__label {
    width: 100%;
    margin-bottom: 20px;
  }
  .polling__imp--wp {
    width: 100%;
  }
  .polling__single--imp input {
    height: 40px;
    padding: 0 16px;
    font-size: 13px;
  }

  .short__fx {
    width: auto;
    background: #fff;
    height: auto;
    padding: 11px 20px;
    border-radius: 20px;
    margin: 0 8px;
  }
  .short__fx {
    width: auto;
    background: #fff;
    height: auto;
    padding: 7px;
    border-radius: 20px;
    margin: 0 8px;
  }
  .scan__fx {
    display: block;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .scan__fx .short__answer--title {
    margin-bottom: 10px;
  }

  .augmented__inp input {
    width: 100%;
    height: 50px;
    border: 1px solid #ededed;
    padding: 0 20px;
    border-radius: 39px;
    margin: auto;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #7d7d7d;
  }
  .augmented.up--wd {
    width: 100%;
    display: flex;
    justify-content: start;
  }

  .test__bottom--imp {
    display: block;
  }
  .test__bottom--imp input {
    width: 100%;
    height: 45px;
    border-radius: 32px;
    padding: 0 15px;
    font-size: 12px;
  }
  .test__bottom--imp button {
    width: 162px;
    height: 45px;
    font-size: 14px;
    line-height: 21px;
    margin-top: 10px;
  }

  .test__btm p {
    font-size: 12px;
  }
  .reject__tx p {
    font-size: 12px;
    color: #262626;
    line-height: 22px;
    font-weight: 400;
    color: #262626;
    margin: 0;
  }
  .view__task__wrap {
    margin-top: 20px;
  }
  .view__task__sm--img {
    width: 80px;
    height: 80px;
    margin-right: 20px;
  }

  .view__task__ck ul li {
    margin-right: 16px;
  }
  .view__task__ck {
    margin-bottom: 20px;
  }
  .short__ans h4 {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 9px;
  }

  .hints_single--item p {
    margin: 0;
    font-size: 11px;
    line-height: 21px;
  }
  .hints_single--item span {
    font-size: 25px;
    font-weight: 700;
    line-height: 50px;
    top: 8px;
    left: 15px;
  }
  .rewards__img {
    width: 60px;
    height: 60px;
  }
  .reward__ct p {
    margin: 0;
    font-family: Poppins;
    font-size: 14px;
    line-height: 27px;
    color: #262626;
  }
  .hints__wrap.re--sec {
    padding-bottom: 40px;
  }
  .task__list {
    width: 100%;
    background: #f7f8fa;
    border-radius: 16px;
    padding: 24px;
    margin-bottom: 40px;
  }
  .add__hint__fx {
    display: block;
    align-items: start;
    margin-top: 20px;
  }
  .add__hint--label label {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .add__hint__inner {
    width: 100%;
  }
  .up--wp {
    display: block;
    align-items: center;
    padding-bottom: 41px;
  }
  .up--wp a {
    margin: auto;
    margin-bottom: 5px;
  }
  .upload__ig {
    width: 100%;
  }

  .header___bar {
    display: block;
  }

  .dash-link {
    width: 100%;
    height: 60px;
  }

  .dash-item {
    width: 50%;
  }
  .dash-tabs {
    width: 100%;
  }

  .sidebar.active {
    left: 0;
    z-index: 9;
  }

  .place__single---input.place-wd .single-input {
    width: 100%;
  }
  .place__single---input.place-wd .single-input span {
    margin-right: 23px;
  }
}

/* SM Small Device :550px. */
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .modal-dialog {
    max-width: 500px;
    margin: auto;
  }
}
