/* Base CSS */
/* autoprefixer: off */

.title-bold {
	font-weight: 700;
	font-size: 18px;
	line-height: 27px;
}

.black {
	color: #262626;
}

.black-grey {
	color: #5a5a60;
}

.regular-14 {
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
}

.h3-bold {
	font-weight: 700;
	font-size: 24px;
	line-height: 120%;
}

.btn-green {
	background: #2c7d92;
	border-radius: 50px;
	width: 160px;
	height: 50px;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 0;
}

.btn-cancel {
	background: #fff;
	border-radius: 50px;
	width: 160px;
	height: 50px;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	color: #f15a5b;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #f15a5b;
}

.radio-toolbar {
	position: relative;
}

.radio-toolbar input[type="radio"] {
	opacity: 0;
	position: absolute;
	width: 128px;
}

.radio-toolbar label {
	display: inline-block;
	background-color: #f7f8fa;
	padding: 10px 20px;
	border-radius: 16px;
	color: #7d7d7d !important;
}

.radio-toolbar label:hover {
	background-color: #2c7d921a;
}

.radio-toolbar input[type="radio"]:focus + label {
	background-color: #2c7d921a;
	border: 1px solid #2c7d92;
	border-radius: 16px;
}

.radio-toolbar input[type="radio"]:checked + label {
	background-color: #2c7d921a;
	border: 1px solid #2c7d92;
	border-radius: 16px;
	color: #2c7d92 !important;
}

.avatar-circle {
	width: 100px;
	height: 100px;
	border-radius: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 0 36px;
	cursor: pointer;
}

.overlay-avatar {
	width: inherit;
	height: inherit;
	background: #696969;
	transition: 0.3s;
	opacity: 0;
	border-radius: 24px;
}

.overlay-avatar a {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.overlay-avatar:hover {
	opacity: 0.9;
	border-radius: 100%;
}

.alignleft {
	float: left;
	margin-right: 15px;
}
.alignright {
	float: right;
	margin-left: 15px;
}
.aligncenter {
	display: block;
	margin: 0 auto 15px;
}
a:focus {
	outline: 0 solid;
}
* {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-appearance: none !important;
	-moz-appearance: none !important;
	appearance: none !important;
}
img {
	max-width: 100%;
	height: auto;
}
input {
	-webkit-appearance: none !important;
	-moz-appearance: none !important;
	appearance: none !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0 0 15px;
	color: #1b1b21;
	font-family: "Poppins", sans-serif;
}
body {
	color: #545051;
	font-weight: 300;
	font-family: "Poppins", sans-serif;
	background: #ffffff;
}

@font-face {
	font-family: RusticLoveTattoo;
	src: url(../fonts/RusticLoveTattoo.otf);
}

.selector-for-some-widget {
	box-sizing: content-box;
}
a:hover {
	text-decoration: none;
	color: #f15a5b;
}

button:focus {
	outline: 0;
	outline: 5px auto -webkit-focus-ring-color;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

.btn-questeon {
	background: #2c7d92;
	border-radius: 50px;
	min-width: 160px;
	padding-left: 25px;
	padding-right: 25px;
	height: 50px;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	color: #ffffff;
}
.btn-questeon.btn-sm {
	height: 40px;
	font-size: 14px;
	min-width: 130px;
}
.btn-questeon.outlined {
	background: #ffffff;
	border: 1px solid #2c7d92;
	box-sizing: border-box;
	color: #2c7d92;
}

.circle-img {
	overflow: hidden;
	position: relative;
	border-radius: 100%;
}
.circle-img img {
	object-fit: cover;
	min-width: 100%;
	min-height: 100%;
}

/* Header bar section start */

/* The container */
.containerss {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.containerss input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
	appearance: none !important;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
	background-color: #eee;
}

/* On mouse-over, add a grey background color */
.containerss:hover input ~ .checkmark {
	background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containerss input:checked ~ .checkmark {
	background-color: #2c7d92;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.containerss input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.containerss .checkmark:after {
	left: 8px;
	top: 4px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

/* singin-area-start */

.singin-area {
	height: 100vh;
	display: flex;
	align-items: center;
	background: #f7f8fa;
}
.singin-box {
	background: #ffffff;
	border: 1px solid #ededed;
	box-sizing: border-box;
	border-radius: 40px;
	max-width: 440px;
	margin: auto;
	padding: 60px 50px;
}
.site-logo {
	margin-bottom: 48px;
}
.singin-title h3 {
	color: #262626;
	font-weight: 700;
	font-size: 24px;
	line-height: 120%;
	margin-bottom: 6px;
}
.singin-title p {
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	/*! text-align: center; */
	color: #5a5a60;
	margin: 0;
	/*! margin-left: -39px; */
}
.singin-title {
	margin-bottom: 45px;
}
.single-input {
	margin-bottom: 25px;
}
.single-input label {
	font-size: 14px;
	line-height: 21px;
	color: #7d7d7d;
	display: block;
	margin-bottom: 6px;
}
.single-input label sup {
	color: red;
	font-size: 12px;
}
.single-input input {
	border: 1px solid #ededed;
	box-sizing: border-box;
	border-radius: 24px;
	height: 50px;
	width: 100%;
	background: #fff;
	padding: 0 24px;
	padding-right: 52px;
	font-size: 14px;
	line-height: 21px;
	color: #000;
	font-weight: 400;
	transition: 0.3s;
}
input:focus {
	outline: none !important;
	box-shadow: unset !important;
	border-color: #ededed !important;
}
.single-input input:focus {
	background: #f7f8fa;
	color: #000;
	opacity: 1;
}
.single-input-eye {
	position: relative;
}
.single-input-eye a {
	position: absolute;
	right: 13px;
	top: 50%;
	transform: translateY(-50%);
	color: #8a8a8a;
}
.single-check-fl {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 25px;
	/*   width: 70%; */
}
.single-check label {
	width: auto;
}
.single-check {
	margin-right: 60px;
}
.single-check-fl label {
	margin: 0;
	font-size: 14px;
	line-height: 21px;
	color: #5a5a60;
	font-weight: 400;
	padding-left: 30px;
	width: auto !important;
}
.single-check-fl label .checkmark {
	width: 20px;
	height: 20px;
	background: #ededed;
	border-radius: 5px;
}
.single-check-fl a {
	font-weight: 700;
	font-size: 14px;
	line-height: 21px;
	color: #2c7d92;
}
.singin-btn button {
	background: #2c7d92;
	border-radius: 50px;
	width: 340px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: #ffffff;
	border: 0;
}
.singin-btn {
	margin-bottom: 24px;
}
.singin-text p {
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	color: #5a5a60;
	text-align: center;
	margin: 0;
}
.singin-text p a {
	color: #2c7d92;
	font-weight: 700;
	font-size: 14px;
}

.singin-btn button img {
	margin-right: 10px;
}

.singin-area {
	position: relative;
}
.singin-area .single-check-fl {
	width: 100%;
}
.notice {
	position: absolute;
	left: 0;
	top: 60px;
	right: 0;
	margin: auto;
	background: #f15a5b;
	border-radius: 16px;
	max-width: 440px;
	height: 60px;
	z-index: 9;
	display: flex;
	align-items: center;
	justify-content: center;
	display: none;
}
.notice.success {
	background: #2FAE55;
}
.notice p {
	font-weight: bold;
	font-size: 14px;
	line-height: 21px;
	color: #ffffff;
	margin: 0;
}
.single-input label span {
	color: red;
}
/* singin-area-end */

/* verify-area-start */

.verify-img {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 30px;
}
.verify-text h3 {
	color: #262626;
	font-weight: 700;
	font-size: 24px;
	line-height: 120%;
}
.verify-containt {
	text-align: center;
}
.singin-box.hig .verify-img {
	margin-bottom: 60px;
}
.verify-text p {
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	text-align: center;
	color: #5a5a60;
}
.verify-text p a {
	font-weight: 700;
	font-size: 14px;
	line-height: 21px;
	text-align: center;
	color: #5a5a60;
}
.verify-box-last-text {
	padding-top: 130px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.verify-box-last-text p {
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	text-align: center;
	color: #5a5a60;
	margin: 0;
	margin-right: 5px;
}
.verify-box-last-text a,
.verify-box-last-text button {
	font-weight: 700;
	font-size: 14px;
	line-height: 21px;
	text-align: center;
	color: #2c7d92;
	text-decoration: none;
}
.singin-text.pd {
	padding-top: 120px;
}
.singin-box.hig {
	height: 660px;
}
.singin-box.hig .singin-btn {
	margin: 0;
	margin-top: 60px;
}
/* verify-area-end */

/* dashbord-page-start */

/* sidebar-start */
.dashboard-container {
	display: flex;
	justify-content: space-between;
}
.sidebar {
	width: 15.63%;
	position: fixed;
	height: 100vh;
	background: #f7f8fa;
	transition: 0.3s;
}
.sidebar-logo {
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom: 1px solid #f1f1f2;
}

.sidebar-content {
	height: 92%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 40px 24px;
}
.user {
	text-align: center;
	margin-bottom: 40px;
}
.user-img {
	margin: auto;
	margin-bottom: 24px;
	width: 100px;
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	border-radius: 100%;
	position: relative;
}
.user-img img {
	object-fit: cover;
	min-width: 100%;
	min-height: 100%;
}
.user-text h3 {
	color: #262626;
	font-size: 18px;
	line-height: 27px;
	font-weight: 700;
	margin-bottom: 4px;
}
.user-text p {
	font-size: 14px;
	line-height: 21px;
	color: #7d7d7d;
	font-weight: 400;
	margin: 0;
}
.sidebar-menu ul {
	padding: 0;
	margin: 0;
	list-style: none;
}
.sidebar-menu ul li a {
	height: 55px;
	display: flex;
	align-items: center;
	border-radius: 50px;
	padding: 0 20px;
	color: #7d7d7d;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	transition: 0.3s;
}
.sidebar-menu ul li a img {
	margin-right: 10px;
}
.sidebar-menu ul li a:hover {
	background: #e3ecf0;
	color: #2c7d92;
	font-weight: 700;
}
.sidebar-menu ul li a:hover img {
	filter: invert(44%) sepia(59%) saturate(462%) hue-rotate(145deg)
		brightness(86%) contrast(93%);
}
.sidebar-logout a {
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #f15a5b;
	padding: 0 12px;
}
.sidebar-logout a img {
	margin-right: 10px;
}

.main-content {
	width: 84.37%;
	margin-left: 15.63%;
	background: #fff;
}
.dash-breadcum {
	background-color: inherit;
	z-index: 999;
	height: 80px;
	display: flex;
	align-items: center;
	padding: 0 32px;
	border-bottom: 1px solid #f4f4f4;
	justify-content: space-between;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
}
.dash-breadcum p {
	font-size: 18px;
	line-height: 27px;
	color: #7d7d7d;
	margin: 0;
	font-weight: 700;
}
.main-content-body {
	padding: 20px 24px;
	background-color: #ffffff;
}
.dash-tab-fl {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.dash-tab-create {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	height: calc(100vh - 120px);
}
.dash-tab-left {
	width: 78%;
}
.dash-tab-right {
	width: 20%;
}
.dash-tab {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.dash-tab ul li a {
	background: transparent !important;
	height: 60px;
	width: 200px;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	align-items: center;
	text-align: center;
	color: #262626;
	justify-content: center;
	border-bottom: 1px solid #f8f8f8;
	cursor: pointer;
}
.dash-tab ul li a:hover {
	color: inherit;
}
.dash-tab ul li a span {
	width: 24px;
	height: 24px;
	background: #ffcf50;
	color: #262626;
	font-size: 12px;
	line-height: 16px;
	font-weight: 700;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	margin-left: 10px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
	color: #2c7d92;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	border-bottom: 1px solid #2c7d92;
	border-radius: 0 !important;
}
.dash-create-btn a {
	background: #2c7d92;
	border-radius: 50px;
	width: 180px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	color: #ffffff;
}
.dash-create-btn a img {
	margin-right: 6px;
}

.dash-tab-containt {
	padding-top: 120px;
	padding-bottom: 120px;
	text-align: center;
}
.dash-tab-img {
	margin-bottom: 24px;
}
.dash-tab-text h3 {
	font-weight: 700;
	font-size: 18px;
	line-height: 27px;
	color: #7d7d7d;
	margin-bottom: 6px;
}
.dash-tab-text p {
	font-size: 14px;
	line-height: 21px;
	margin-bottom: 0;
	color: #7d7d7d;
	font-weight: 400;
}
.dash-tab-text {
	margin-bottom: 40px;
}

.dash-tab-btn a {
	background: #2c7d92;
	border-radius: 50px;
	height: 50px;
	width: 230px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: auto;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	color: #fff;
}
.dash-tab-btn a img {
	margin-right: 6px;
}
.dash-tab-ri-btn a {
	height: 50px;
	display: flex;
	align-items: center;
	font-weight: 700;
	font-size: 18px;
	line-height: 27px;
	display: flex;
	color: #262626;
	align-items: center;
}
.dash-tab-ri-btn a img {
	margin-right: 9px;
}
.dash-tab ul {
	padding: 0;
	margin: 0 !important;
}
.dash-tab-ri-jion-time {
	padding-top: 125px;
	text-align: center;
	max-width: 164px;
	margin: auto;
}
.dash-tab-ri-jion-time img {
	margin-bottom: 20px;
}
.dash-tab-ri-jion-time p {
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	color: #7d7d7d;
	margin: 0;
}

.dash-tab-containt {
	display: none;
}

.dash-popular-area {
	display: grid;
	flex-wrap: wrap;
	padding: 24px 0;
	justify-content: space-between;
	grid-gap: 15px;
	grid-template-columns: 32% 32% 32%;
}
.single-popular {
	background: #f7f8fa;
	border-radius: 24px;
	overflow: hidden;
	margin-bottom: 24px;
}
.popular-img {
	width: 100%;
	height: 250px;
	overflow: hidden;
}
.popular-img img {
	object-fit: cover;
	min-width: 100%;
	min-height: 100%;
}
.popular-text {
	padding: 16px;
}
.popular-text h3 {
	font-weight: 700;
	font-size: 18px;
	line-height: 27px;
	color: #262626;
	margin-bottom: 5px;
}
.popular-text a {
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #7d7d7d;
	display: block;
	margin-bottom: 7px;
}
.popular-text a img {
	max-width: 16px;
	margin-right: 7px;
}
.dash-tab-ri-jion-time {
	display: none;
}

.dash-join {
	padding-top: 24px;
}
.single-join {
	background: #f7f8fa;
	border-radius: 24px;
	padding: 20px;
	margin-bottom: 12px;
	display: flex;
}
.join-img {
	margin-right: 20px;
	width: 50px;
	height: 50px;
	overflow: hidden;
	border-radius: 100%;
}
.join-img img {
	object-fit: cover;
	min-width: 100%;
	min-height: 100%;
}
.join-text h3 {
	font-weight: 700;
	font-size: 14px;
	line-height: 21px;
	color: #7d7d7d;
	margin-bottom: 2px;
}

.join-text p a {
	color: #2c7d92;
}
.join-text p a:visited {
	color: #2c7d92;
}
.join-text p {
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	color: #7d7d7d;
	margin-bottom: auto;
}

.join-text p.date-time {
	margin-top: 10px;
}

.table-area {
	padding-top: 24px;
}
.table-search input {
	background: #ffffff;
	border: 1px solid #ededed;
	box-sizing: border-box;
	border-radius: 50px;
	width: 100%;
	padding: 0px 20px;
	height: 50px;
	padding-left: 52px;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	color: #7d7d7d;
}
.table-search {
	position: relative;
	margin-bottom: 24px;
}
.table-search a {
	color: #2c7d92;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 20px;
}
.table td .quest-badge {
	display: inline-block;
	width: 40px;
	height: 40px;
}
th {
	border: 0 !important;
}
.table td,
.table th {
	border: 0 !important;
	vertical-align: middle;
}
.table thead th {
	vertical-align: middle;
	background: #f7f8fa;
	padding-left: 20px;
	padding-right: 20px;
	font-weight: 700;
	font-size: 14px;
	line-height: 21px;
	color: #7d7d7d;
}
.table thead th i {
	margin-left: 8px;
}
.table-box tr {
	border: 16px;
	overflow: hidden;
	cursor: pointer;
}
.table-box th {
	white-space: nowrap;
}
.table td {
	padding: 24px 20px;
	font-size: 14px;
	line-height: 21px;
	color: #262626;
}
.table-box td .status {
	border-radius: 8px;
	padding: 4px 16px;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	white-space: nowrap;
	margin: auto;
}
.ta-btn.on_review {
	background: #ededed;
	color: #7d7d7d;
}
.ta-btn.draft {
	background: #5a5a60;
	color: #fff;
}
.ta-btn.publish {
	background: rgba(47, 174, 85, 0.15);
	color: #2fae55;
}
.table-box {
	margin-bottom: 40px;
}
.table-nav {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.table-nav p {
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	color: #5a5a60;
	margin: 0;
}
.table-nav-fl {
	display: flex;
	align-items: center;
	justify-content: center;
}
.table-nav-fl a {
	font-weight: bold;
	font-size: 14px;
	line-height: 21px;
	display: flex;
	align-items: center;
	color: #5a5a60;
	justify-content: center;
	width: 32px;
	height: 32px;
	border-radius: 3px;
}
.table-nav-fl a.actives {
	background: #eaf2f4;
	color: #2c7d92;
}

.bg-table td {
	/*! background: #EAF2F4; */
	transition: 0.3s;
}
table td:nth-child(1) {
	border-top-left-radius: 16px;
	border-bottom-left-radius: 16px;
}
table td:nth-child(1) {
	font-weight: 700;
}
.bg-table td:last-child {
	border-top-right-radius: 16px;
	border-bottom-right-radius: 16px;
}
.table thead th:nth-child(1) {
	border-top-left-radius: 16px;
	border-bottom-left-radius: 16px;
}
.table thead th:last-child {
	border-top-right-radius: 16px;
	border-bottom-right-radius: 16px;
}

/* sidebar-end */

/* create-quest-page-css-start */
.containerk {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	padding-left: 30px !important;
}

/* Hide the browser's default checkbox */
.containerk input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.containerk .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
	background-color: #eee;
}

/* On mouse-over, add a grey background color */
.containerk:hover input ~ .checkmark {
	background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containerk input:checked ~ .checkmark {
	background-color: #2c7d92;
}

/* Create the checkmark/indicator (hidden when not checked) */
.containerk .checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.containerk input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.containerk .checkmark:after {
	left: 9px;
	top: 6px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.breadcum-fl {
	display: flex;
	align-items: center;
}
.breadcum-fl a {
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #7d7d7d;
}
.breadcum-fl span {
	font-size: 20px;
	margin: 0 10px;
}
.info-area {
	padding-left: 30px;
}
.info-title h3 {
	font-weight: 700;
	font-size: 24px;
	line-height: 120%;
	color: #262626;
	margin: 0;
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}
.info-title h3 span {
	width: 32px;
	height: 32px;
	background: #ffcf50;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	color: #262626;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	margin-right: 15px;
}
.info-title {
	margin-bottom: 40px;
}
.single-info {
	display: flex;
	margin-bottom: 30px;
}
.single-info label {
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #262626;
	width: 25%;
	margin: 0;
	margin-top: 12px;
	appearance: none !important;
}
.single-info label sup {
	color: #f15a5b;
}
.info-drop-box {
	width: 300px;
	height: 225px;
	background: #f7f8fa;
	border: 1px dashed #2c7d92;
	border-radius: 24px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 0 36px;
	cursor: pointer;
}

.info-drop-box-portrait {
	height: 300px;
	width: 225px;
	background: #f7f8fa;
	border: 1px dashed #2c7d92;
	border-radius: 24px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 0 36px;
	cursor: pointer;
}
.info-drop-box.drop-box-square {
	width: 300px !important;
	height: 300px !important;
}
.info-dorp-img {
	margin-bottom: 26px;
}
.info-dorp-text p {
	font-size: 14px;
	line-height: 21px;
	color: #262626;
	font-weight: 400;
	/*! display: flex; */
	align-items: center;
	margin-bottom: 6px;
}
.info-dorp-text p a {
	color: #2c7d92;
	font-weight: 700;
	margin-left: 2px;
	display: 14px;
}
.info-dorp-text span {
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #7d7d7d;
}
.single-info .single-input {
	width: 75%;
	margin: 0;
}
.single-input textarea {
	width: 100%;
	height: 200px;
	background: #ffffff;
	border: 1px solid #ededed;
	box-sizing: border-box;
	border-radius: 24px;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	color: #7d7d7d;
	padding: 20px 24px;
}
.reword-fl {
	display: flex;
	align-content: center;
	justify-content: space-between;
	width: 75%;
}
.reword-img {
	background: #f7f8fa;
	border: 1px dashed #2c7d92;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100px;
	height: 100px;
}
.reword-select {
	display: flex;
	align-items: center;
	width: 84%;
}
.reword-select .nice-select {
	width: 100%;
	height: 50px;
	border: 1px solid #ededed;
	box-sizing: border-box;
	border-radius: 24px;
	display: flex;
	align-items: center;
	padding: 0 24px;
}
.reword-select .nice-select::after {
	right: 24px;
}
.nice-select::after {
	height: 10px;
	right: 12px;
	top: 50%;
	width: 10px;
}
.single-info.wd .single-input {
	width: 120px;
	position: relative;
}
.single-input span {
	font-weight: bold;
	font-size: 14px;
	line-height: 22px;
	text-align: right;
	color: #262626;
	position: absolute;
	right: 21px;
	top: 50%;
	transform: translateY(-50%);
}
.single-info.wd p {
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	color: #7d7d7d;
	margin-bottom: 0;
	margin-top: 16px;
	margin-left: 24px;
}
.single-inputes {
	width: 75%;
}
.single-inputes .single-input {
	width: 100%;
	position: relative;
	margin-bottom: 16px;
}
.single-inputes .single-input a {
	position: absolute;
	right: 18px;
	top: 47%;
	transform: translateY(-50%);
}
.single-input-add a {
	background: #ffffff;
	border-radius: 50px;
	width: 213px;
	height: 50px;
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	color: #2c7d92;
	display: flex;
	align-items: center;
	justify-content: center;
}
.single-input-add a img {
	filter: invert(44%) sepia(59%) saturate(462%) hue-rotate(145deg)
		brightness(86%) contrast(93%);
	margin-right: 10px;
}
.info-area {
	display: flex;
	justify-content: space-between;
}
.info-check-list {
	width: 20%;
	background: #f7f8fa;
	border-radius: 24px;
	height: 305px;
	padding: 24px;
}
.info-check-list-title {
	margin-bottom: 20px;
}
.info-check-list-title h3 {
	font-weight: 700;
	font-size: 18px;
	line-height: 27px;
	margin: 0;
	color: #262626;
}
.info-single-check label {
	margin-bottom: 20px;
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	color: #c2c2c2;
	padding: 0;
}
.containerk input:checked ~ p {
	color: #000;
}
.containerk input:checked + p span {
	background: #ffcf50;
	color: #000;
}
.info-single-check label span.number {
	width: 24px;
	height: 24px;
	margin-right: 6px;
	background: #c2c2c2;
	color: #5a5a60;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
}
.info-single-check label p {
	display: flex;
	align-items: center;
	margin: 0;
}
.containerk .checkmark {
	left: auto;
	right: 0;
	height: 24px;
	width: 24px;
	border-radius: 50%;
	background: #ededed;
	top: 50%;
	transform: translateY(-50%);
}
.containerk input:checked {
	color: #5a5a60 !important;
}
.info-single-check label:last-child {
	margin: 0;
}
.form-single-btn .btn-nav {
	background: #2c7d92;
	border-radius: 50px;
	width: 160px;
	height: 50px;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: auto;
	border: 0;
}

.info-containt {
	width: 68%;
}

.single-blocks {
	width: 75%;
}
.single-block {
	background: #f7f8fa;
	border-radius: 24px;
	margin-bottom: 20px;
	padding: 24px;
}
.block-title h3 {
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #262626;
	margin: 0;
}
.block-title {
	margin-bottom: 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.blok-input input {
	width: 100%;
	height: 50px;
	background: #ffffff;
	border: 1px solid #ededed;
	box-sizing: border-box;
	border-radius: 24px;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	color: #262626;
	padding: 0 24px;
}
.blok-input {
	margin-bottom: 16px;
}
.blok-input:last-child {
	margin: 0;
}
.blok-input textarea {
	background: #ffffff;
	border: 1px solid #ededed;
	box-sizing: border-box;
	border-radius: 24px;
	height: 100px;
	width: 100%;
	padding: 20px 24px;
	font-size: 14px;
	line-height: 21px;
	color: #262626;
}
.form-single-btn {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.priv {
	margin: 0 !important;
	background: #ffffff !important;
	border: 1px solid #2c7d92 !important;
	box-sizing: border-box;
	color: #2c7d92 !important;
}
.info-area.hi {
	height: 90vh;
}

.bat-title {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 34px;
}
.bat-title h3 {
	font-weight: 700;
	font-size: 24px;
	line-height: 120%;
	color: #262626;
	margin: 0;
}
.bat-title a {
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	color: #2c7d92;
}

.bat-title a img {
	margin-right: 10px;
}

.bat-img img {
	width: 100%;
}

.bat-img {
	position: relative;
	margin-bottom: 1.5rem;
	width: 100%;
	height: 300px;
}

.bat-img > img {
	object-fit: cover;
	min-width: 100%;
	height: 100%;
	border-radius: 20px;
}

.profile-img {
	position: absolute;
	right: 80px;
	width: 115px !important;
	height: 115px;
	bottom: -50px;
}

.bat-free {
	margin-bottom: 1.5rem;
}

.bat-free p {
	display: flex;
	align-items: center;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	color: #5a5a60;
	float: left;
	margin-right: 15px;
	margin-bottom: 0%;
}

.bat-text p {
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	color: #5a5a60;
	margin-bottom: 20px;
}

.bat-text {
	margin-bottom: 40px;
}

.bat-info-fl {
	max-width: 407px;
	display: flex;
	justify-content: space-between;
}

.bat-single-info img {
	margin-bottom: 4px;
}

.bat-single-info p {
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	color: #7d7d7d;
	margin-bottom: 4px;
}

.bat-single-info span {
	font-weight: bold;
	font-size: 24px;
	line-height: 120%;
	color: #262626;
}

sub {
	font-size: 15px;
}

.bat-info-fl {
	margin-bottom: 40px;
}

.bat-hight {
	margin-bottom: 1.5rem;
}

.bat-hight h3 {
	font-weight: 700;
	font-size: 18px;
	line-height: 27px;
	color: #262626;
}

.bat-hight ul {
	padding: 0;
	margin: 0;
	list-style: none;
}

.bat-hight li {
	display: block;
	position: relative;
	font-size: 14px;
	line-height: 21px;
	color: #5a5a60;
	font-weight: 400;
	margin-bottom: 11px;
	padding-left: 20px;
}
.bat-hight li:before {
	position: absolute;
	content: "";
	background: #ffcf50;
	height: 4px;
	width: 4px;
	border-radius: 50%;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
}

.bat-area {
	margin-bottom: 80px;
}
.intro-area {
	display: flex;
	justify-content: space-between;
}
.intro-img {
	width: 30%;
	height: 250px;
	position: relative;
	overflow: hidden;
	border-radius: 15px;
}
.intro-img img {
	object-fit: cover;
	height: 100%;
	min-width: 100%;
}
.intro-containt {
	width: 68%;
}
.intro-top-text p {
	font-weight: 400;
	margin-bottom: 30px;
	line-height: 21px;
	color: #5a5a60;
}
.intro-single-text h3 {
	font-weight: 700;
	font-size: 18px;
	line-height: 27px;
	color: #262626;
	margin-bottom: 5px;
}
.intro-single-text p {
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	color: #5a5a60;
	margin: 0;
}
.intro-single-text {
	margin-bottom: 30px;
}
.intro-single-text:last-child {
	margin: 0;
}

.track-area {
	background: #f7f8fa;
	border-radius: 24px;
	display: flex;
	/*! align-items: center; */
	justify-content: space-between;
	border: 1px solid #ededed;
	margin-bottom: 35px;
}
.treck-right {
	background: #ffffff;
	border-radius: 24px;
	width: 75%;
	padding: 24px;
}
.track-left {
	padding: 24px;
	width: 25%;
}
.track-img {
	width: 200px;
	height: 150px;
	max-width: 100%;
	overflow: hidden;
	position: relative;
	border-radius: 15px;
}
.track-img img {
	object-fit: cover;
	min-width: 100%;
	height: 100%;
}
.track-left-text h3 {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 27px;
	color: #262626;
	margin: 16px 0;
}
.track-left-text p span {
	font-weight: 600;
	margin: 0 9px;
}
.track-left-text p {
	font-size: 14px;
	display: flex;
	align-items: center;
	color: #7d7d7d;
}
.track-ri-top {
	margin-bottom: 25px;
}
.track-ri-top span {
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #7d7d7d;
	display: flex;
	align-items: center;
}
.track-ri-top span img {
	margin-right: 10px;
}
.track-ri-top-text h3 {
	font-weight: bold;
	font-size: 14px;
	line-height: 21px;
	color: #262626;
	margin-bottom: 6px;
}
.track-ri-top-text p {
	font-size: 12px;
	line-height: 16px;
	color: #7d7d7d;
}
.track-ri-text {
	margin-left: 19px;
}
.track-ri-top-text {
	margin-bottom: 25px;
}
.track-ri-top-text .btn-group {
	width: auto;
	text-align: right;
	min-width: 20%;
}

.quest-btn .button {
	background: #2c7d92;
	border-radius: 50px;
	width: 200px;
	height: 50px;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 50px;
	margin: auto;
	border: 0;
}

.single-label {
	background: #ffffff;
	border: 1px solid #ededed;
	border-radius: 20px;
	padding: 19px 20px;
	margin-bottom: 20px;
	appearance: none !important;
}
.label-top {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.label-bottom {
	padding-top: 20px;
}
.label-top-left {
	display: flex;
	align-items: center;
}
.lavel-img {
	margin-right: 20px;
	position: relative;
	overflow: hidden;
	border-radius: 15px;
	width: 80px;
	height: 80px;
}
.lavel-img img {
	object-fit: cover;
	min-width: 100%;
	min-height: 100%;
}

.lavel-text h3 {
	font-weight: 700;
	font-size: 18px;
	line-height: 27px;
	color: #262626;
	margin-bottom: 2px;
}
.lavel-text p {
	font-size: 12px;
	line-height: 16px;
	color: #7d7d7d;
	font-weight: 400;
	margin: 0;
}
.subplace.btn-group {
	font-size: 14px;
	line-height: 16px;
}
.label-icon a {
	background: #c2c2c2;
	height: 20px;
	width: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	color: #fff;
	padding-top: 2px;
}

.label-top[aria-expanded="false"] .label-icon a {
	transform: rotate(-180deg);
}
.label-icon a {
	transition: 0.3s;
}
.label-top[aria-expanded="true"] .label-icon a {
	background: #2c7d92;
}
.single-label:last-child {
	margin: 0;
}
/* create-quest-page-css-end */

.container {
	max-width: 1170px;
	margin: auto;
}

/*addplace--popup--style--start*/

.addplace__popup--btn {
	width: 300px;
	height: 60px;
	margin: auto;
	margin-top: 20px;
	margin-bottom: 50px;
}
.addplace__popup--btn a {
	width: 100%;
	height: 100%;
	background: red;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	color: #fff;
	font-size: 30px;
	border-radius: 5px;
}

.modal-dialog {
	max-width: 800px;
}
.modal-body {
	padding: 0;
}
.modal-content {
	border-radius: 16px;
}

.place__top {
	height: 70px;
	display: flex;
	align-items: center;
	padding-left: 24px;
	border-bottom: 1px solid #f2f2f2;
}
.place__top h4 {
	margin: 0;
	font-family: Poppins;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 27px;
	color: #262626;
}
.place__upload--main label {
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
	margin: 0;
	display: block;
	color: #262626;
	width: 30%;
}
.upload {
	/*! width: 320px; */
	/*! height: 240px; */
	/*! border: 1px solid #2C7D92; */
	/*! border-style: dashed; */
	/*! border-radius: 16px; */
	/*! display: flex; */
	flex-direction: column;
	/*! align-items: center; */
	/*! justify-content: center; */
	/*! background: #F7F8FA; */
}
.place__upload--main {
	width: auto;
	display: flex;
	align-items: start;
	/* justify-content: space-between; */
	margin-bottom: 30px;
}
.place__upload--box {
	padding: 0 40px;
	padding-top: 40px;
}
.upload img {
	max-width: 48px;
	margin-bottom: 24px;
}
.upload p {
	font-size: 14px;
	line-height: 21px;
	color: #262626;
	margin-bottom: 8px;
	text-align: center;
}
.upload span {
	font-family: Poppins;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	color: #7d7d7d;
}
.upload p span {
	font-weight: 700;
	color: #2c7d92;
}

.place__single---input label {
	font-family: Poppins;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	color: #262626;
	width: 28%;
}
.place__single---input label span {
	color: #f15a5b;
}
.place__single---input input {
	width: 69%;
	height: 50px;
	border: 1px solid #ededed;
	border-radius: 59px;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	padding: 0 24px;
}
.place__single---input {
	margin-bottom: 32px;
}

.location--single p {
	font-family: Poppins;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	color: #7d7d7d;
	position: relative;
	margin-bottom: 5px;
}

.location--single a {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 700;
	line-height: 21px;
	color: #c2c2c2;
	text-decoration: none;
}
.location--fx {
	width: 69%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.place__single---input {
	display: flex;
	align-items: center;
	/* justify-content: space-between; */
}

.place__single---input-form-modal {
	display: flex;
	align-items: baseline;
	/* justify-content: space-between; */
}

.single--check label {
	width: 500px;
	color: #262626;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 21px;
}
.single--check containers {
	margin: 0;
}
.single--check .containers {
	margin: 0;
}

.place__single---input.place-wd input {
	width: 20%;
	/* margin-left: 20px; */
}
.place__single---input.place-wd {
	justify-content: start;
}
#test {
	width: 100%;
	height: 450px;
}

.place__map {
	margin-bottom: 40px;
}

.map__btn {
	display: flex;
	align-items: center;
	justify-content: end;
	padding-bottom: 40px;
}

/*addplace--popup--style--end*/

/*radio-button--style-start*/
.containers {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.containers input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 24px;
	width: 24px;
	background-color: #ededed;
	border-radius: 5px;
}

/* When the checkbox is checked, add a blue background */
.containers input:checked ~ .checkmark {
	background-color: #2c7d92;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.containers input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.containers .checkmark:after {
	left: 9px;
	top: 5px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}
/*radio-button--style-end*/

/*view--page--style--start*/

body {
	background: #ffffff;
}

.view__header__lf--bar {
	width: 20%;
	height: 100vh;
}

.view__page--body {
	width: 80%;
}

.view__header--fx {
	display: flex;
	align-items: start;
	justify-content: space-between;
}

.view__header__menu {
	height: 80px;
	display: flex;
	align-items: center;
	background: #fff;
	padding-left: 32px;
}

.view__header__menu ul li {
	display: inline-block;
	margin-right: 20px;
}

.view__header__menu ul li a {
	font-family: Poppins;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	text-decoration: none;
	color: #7d7d7d;
	transition: 0.3s;
}

.view__header__menu ul li a i {
	padding-left: 20px;
}

.view__header__menu ul li a:hover {
	color: #2c7d92;
}

.view__banner {
	margin-bottom: 0;
	height: 300px;
	overflow: hidden;
}

.view__banner img {
	object-fit: cover;
	min-width: 100%;
	min-height: 100%;
}

.view__logo {
	padding: 25px 0;
	text-align: center;
	border-bottom: 1px solid #efeff0;
}

.view__lf--bar--content {
	padding: 40px 0;
	text-align: center;
}

.view__lf--bar--content img {
	max-width: 80px;
	margin-bottom: 32px;
}

.view__lf--bar--content h4 {
	font-family: Poppins;
	font-size: 18px;
	font-weight: 700;
	line-height: 27px;
	color: #262626;
	margin-bottom: 4px;
}

.view__lf--bar--content p {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	color: #7d7d7d;
}

.view__lf--bar-menu ul li a {
	width: 252px;
	height: 55px;
	display: flex;
	align-items: center;
	margin: auto;
	font-family: Poppins;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	text-decoration: none;
	color: #7d7d7d;
}

.view__lf--bar-menu ul li a img {
	margin-right: 10px;
}

.sign__btn {
	margin-top: 375px;
}

.sign__btn a {
	width: 252px;
	height: 55px;
	display: flex;
	align-items: center;
	margin: auto;
	font-family: Poppins;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	text-decoration: none;
	color: #f15a5b;
}

.sign__btn a img {
	margin-right: 10px;
}

.botanic__title h3 {
	font-family: Poppins;
	font-size: 36px;
	font-weight: 700;
	line-height: 43px;
	color: #262626;
	margin: 0;
}

.botanic__button .btn {
	width: 160px;
	height: 46px;
	background: #2c7d92;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-family: Poppins;
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	text-decoration: none;
	border: 2px solid transparent;
	transition: 0.3s;
	border-radius: 100px;
}
.botanic__button .btn {
	margin-right: 16px;
}

.botanic__button {
	display: flex;
	align-items: center;
}

.botanic__button span {
	width: 50px;
	height: 46px;
	color: #f15a5b;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.botanic__button .btn i {
	margin-right: 10px;
}

.botanic__button .btn:nth-child(2) {
	background: transparent;
	border: 1px solid #2c7d92;
	color: #2c7d92;
}

.botanic__top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 25px;
}

.botanic__area {
	padding-left: 50px;
	padding-right: 30px;
	background: #ffffff;
	padding-top: 20px;
}

.single--star p {
	color: #5a5a60;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 700;
	line-height: 21px;
	display: flex;
	align-items: center;
	margin-right: 16px;
	margin-bottom: 0;
}

.single--star p img.green, img.green {
	filter: invert(40%) sepia(45%) saturate(2966%) hue-rotate(129deg)
		brightness(98%) contrast(101%);
}

.single--star p i {
	color: #ffcf50;
	margin-right: 4px;
}

.single--star {
	display: flex;
	align-items: center;
}

.botanic__star {
	margin-bottom: 35px;
}

.view-link {
	width: 170px;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: transparent;
	border: 0;
	font-family: Poppins;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	color: #262626;
	position: relative;
	cursor: pointer;
}

.view-link:hover {
	color: inherit;
}

.view-tabs {
	border-bottom: 1px solid #f1f1f1;
	margin-bottom: 40px;
}

.user__lf {
	width: 60%;
}

.user--tab--contrent p {
	margin: 0;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	color: #5a5a60;
}

.user--tab--check {
	margin-top: 40px;
	margin-bottom: 40px;
}

.user--tab--check {
	display: flex;
	align-items: center;
}

.check__single--item {
	width: 150px;
}

.check__single--item img {
	margin-bottom: 5px;
}

.check__single--item p {
	margin-bottom: 5px;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	color: #7d7d7d;
}

.reword__wrap h4 {
	font-family: Poppins;
	font-size: 18px;
	font-weight: 700;
	line-height: 27px;
	margin-bottom: 16px;
	color: #262626;
}

.reward__box {
	width: 327px;
	height: 100px;
	background: #F7F8FA;
	display: flex;
	align-items: center;
	padding: 20px 25px;
	border-radius: 15px;
}

.reward__img {
	width: 60px;
	height: 60px;
	margin-right: 16px;
}

.reward__conntent {
	width: 203px;
}

.reward__conntent h4 {
	margin: 0;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 700;
	line-height: 21px;
	color: #262626;
}

.reward__img img {
	width: 100%;
	height: 100%;
}

.reword__wrap {
	margin-bottom: 40px;
}

.Highlights__wrap h4 {
	font-family: Poppins;
	font-size: 18px;
	font-weight: 700;
	line-height: 27px;
	margin-bottom: 16px;
	color: #262626;
}

.highlight__menu ul li {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 35px;
	color: #5a5a60;
	padding-left: 20px;
	position: relative;
}

.highlight__menu ul li:before {
	position: absolute;
	width: 5px;
	height: 5px;
	background: #ffcf50;
	content: "";
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	border-radius: 50%;
}

.Highlights__wrap {
	padding-bottom: 40px;
	border-bottom: 1px solid #ededed;
	margin-bottom: 40px;
}

.user__ri {
	width: 36%;
}

.user__fx {
	display: flex;
	align-items: start;
	justify-content: space-between;
}

.user_ri--single-box {
	width: 100%;
	height: 130px;
	background: #f7f8fa;
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: 0 24px;
	border-radius: 20px;
}

.user_ri--single-box p {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 700;
	line-height: 21px;
	margin-bottom: 8px;
	color: #5a5a60;
}

.nice-select {
	width: 100%;
	height: 50px;
	border: 1px solid #eeeeef;
	background: #fff;
	border-radius: 52px;
	display: flex;
	align-items: center;
	padding: 0 24px;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	color: #262626;
}

.user__single--select .nice-select::after {
	border-width: 3px;
	border-color: #7d7d7d;
	width: 10px;
	height: 10px;
	right: 30px;
}

.recent__user__join {
	margin-top: 16px;
}

.recent__title h4 {
	font-family: Poppins;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 27px;
	color: #262626;
	margin: 0;
}

.recent__title {
	margin-bottom: 24px;
}

.recent__title h4 img {
	margin-right: 10px;
}

.join__single--blog {
	width: 100%;
	height: 120px;
	background: #f7f8fa;
	margin-bottom: 15px;
	display: flex;
	align-items: center;
	padding: 0 20px;
	border-radius: 15px;
}

.lee--img {
	width: 80px;
	height: 80px;
	margin-right: 20px;
}

.lee--img img {
	width: 100%;
	height: 100%;
}

.lle__content h5 {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 700;
	line-height: 21px;
	color: #7d7d7d;
	margin-bottom: 2px;
}

.lle__content p {
	font-family: Poppins;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	color: #7d7d7d;
	margin-bottom: 8px;
}
.lle__content span {
	font-family: Poppins;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	color: #7d7d7d;
}

.intro__title h4 {
	font-family: Poppins;
	font-size: 24px;
	font-weight: 700;
	line-height: 29px;
	margin-bottom: 16px;
	color: #262626;
}
.intro__img {
	width: 300px;
	height: 250px;
	position: relative;
	overflow: hidden;
	border-radius: 15px;
}

.intro__img img {
	object-fit: cover;
	height: 100%;
	min-width: 100%;
}

.intro__content {
	width: 400px;
	margin-left: 50px;
}
.intro__content p {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	color: #5a5a60;
	margin-bottom: 32px;
}
.intro__single--blog h5 {
	font-family: Poppins;
	font-size: 18px;
	font-weight: 700;
	line-height: 27px;
	margin-bottom: 8px;
}
.intro__single--blog p {
	margin-bottom: 0;
}
.intro__fx {
	display: flex;
	align-items: start;
	padding-bottom: 50px;
	border-bottom: 1px solid #ededed;
	margin-bottom: 40px;
}
.intro__single--blog {
	margin-bottom: 30px;
}

.comnc__box {
	max-width: 680px;
	padding-bottom: 80px;
}

.comnc__box h4 {
	font-family: Poppins;
	font-size: 24px;
	font-weight: 700;
	line-height: 29px;
	color: #262626;
}

.comnc__box p {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	margin: 0;
	color: #5a5a60;
}

.place__top--title h4 {
	font-size: 18px;
	line-height: 27px;
	color: #262626;
	margin: 0;
	font-weight: 700;
}
.place__menu ul li {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 700;
	line-height: 21px;
	display: flex;
	align-items: center;
	text-decoration: none;
	color: #7d7d7d;
}
.place__menu ul li img {
	margin-right: 7px;
	margin-left: 15px;
}
.place__menu ul li {
	/* margin-right: 25px; */
	display: inline-block;
}
.place__top__task {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 40px;
}
.place__box {
	border: 1px solid #ededed;
	padding: 0;
	border-radius: 15px;
	background: #f7f8fa;
	display: flex;
	align-items: start;
	justify-content: space-between;
	min-height: 302px;
	margin-bottom: 30px;
}
.place__lf {
	width: 32%;
	padding: 24px;
	min-height: 302px;
}
.place__img {
	margin-bottom: 16px;
	position: relative;
	overflow: hidden;
	border-radius: 15px;
	width: 170px;
	height: 135px;
}
.place__img img {
	object-fit: cover;
	min-width: 100%;
	min-height: 100%;
}
.place__img--title h4 {
	font-family: Poppins;
	font-size: 18px;
	font-weight: 700;
	line-height: 27px;
	color: #262626;
}
.place__ri {
	width: 66%;
	background: #ffffff;
	padding: 24px 30px;
	min-height: 302px;
	border-radius: 20px 15px 15px 20px;
}
.place__cal span {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 700;
	line-height: 21px;
	display: flex;
	align-items: center;
	text-decoration: none;
	color: #7d7d7d;
}
.place__cal span i {
	margin-right: 8px;
}
.place__cal {
	margin-bottom: 20px;
}
.place__pond--contnt h5 {
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 21px;
	letter-spacing: 0em;
	text-align: left;
	margin-bottom: 4px;
	color: #262626;
}
.place__pond--contnt p {
	margin: 0;
	font-family: Poppins;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	color: #7d7d7d;
}
.place__details a {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 700;
	line-height: 21px;
	color: #2c7d92;
	text-decoration: none;
}
.place__pond__single--box {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 70px;
	width: 100%;
	padding: 0 20px;
	border-radius: 16px;
}
.place__pond__single--box.place__bg {
	background: #eaf2f4;
}
.place__wuay--tx p {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	color: #7d7d7d;
	margin-bottom: 24px;
}
.place__wuay--tx span {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 700;
	line-height: 21px;
	display: flex;
	align-items: center;
	text-decoration: none;
	color: #7d7d7d;
	margin-bottom: 20px;
}
.place__wuay--tx span i {
	margin-right: 8px;
}
.chupit__box {
	width: 100%;
	border: 1px solid #f2f2f2;
	border-radius: 16px;
	padding: 20px;
	margin-bottom: 20px;
}
.chupit__img {
	width: 100px;
	height: 100px;
	margin-right: 20px;
	position: relative;
	overflow: hidden;
	border-radius: 10px;
}
.chupit__img img {
	object-fit: cover;
	min-width: 100%;
	min-height: 100%;
}
.chupit__content h5 {
	font-family: Poppins;
	font-size: 18px;
	font-weight: 700;
	line-height: 27px;
	color: #262626;
	margin-bottom: 4px;
}
.chupit__content p {
	font-family: Poppins;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	color: #7d7d7d;
}
.chupit__icon span {
	width: 25px;
	height: 25px;
	background: #c2c2c2;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 19px;
	color: #fff;
	font-size: 12px;
}
.chupit__top--lf {
	display: flex;
	align-items: center;
}
.chupit__top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
}
.chupuit__task ul li {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 700;
	line-height: 21px;
	display: flex;
	align-items: center;
	text-decoration: none;
	color: #7d7d7d;
}
.chupuit__task ul li img {
	margin-right: 8px;
}
.place__box.place__box--sec {
	height: auto;
}

.place__img--title p {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 700;
	line-height: 21px;
	color: #7d7d7d;
	margin-bottom: 18px;
}
.place__img--title p span {
	font-weight: 400;
}
.place__img--title p i {
	margin-right: 10px;
}
.chupuit__task {
	margin-bottom: 20px;
}

.place__table tr th {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 700;
	line-height: 21px;
	background: #f7f8fa;
	border-bottom: 0;
	padding: 15px 0;
	vertical-align: middle;
}
.place__table tr td img {
	max-width: 40px;
	margin-right: 10px;
}
.place__table tr th:first-child {
	padding-left: 20px;
	border-radius: 16px 0 0px 16px;
}
.place__table tr th:last-child {
	border-radius: 0 16px 16px 0;
	text-align: center;
}
.place__table tr th img {
	margin-left: 5px;
}
.place__table tr td {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	color: #262626;
	vertical-align: middle;
	border-bottom: 0;
}

.view-link:before {
	position: absolute;
	width: 0;
	height: 2px;
	background: #2c7d92;
	content: "";
	left: 0;
	bottom: -1px;
	transition: 0.3s;
}
.view-link.active::before {
	width: 100%;
}
.view-link.active {
	color: #2c7d92;
	font-weight: 700;
}
.place__table tr td:last-child {
	text-align: center;
}
.qst-progress {
	width: 180px;
	height: 8px;
	background: #ededed;
	position: relative;
}
.qst-progress:before {
	position: absolute;
	width: 20%;
	height: 100%;
	background: #2c7d92;
	content: "";
	left: 0;
	top: 0;
	border-radius: 24px;
}
.progress__ct span {
	font-family: Poppins;
	font-size: 12px;
	font-weight: 700;
	line-height: 16px;
	color: #5a5a60;
}

.progress__fx {
	display: flex;
	align-items: center;
}
.progress__ct {
	margin-left: 25px;
}

.result_lf p {
	margin: 0;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
}
.result__number ul li {
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 700;
	line-height: 21px;
	justify-content: center;
	border-radius: 5px;
}
.result__number ul {
	display: flex;
	align-items: center;
}
.result__number ul li:nth-child(2) {
	background: #eaf2f4;
}

.table__result {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 40px;
}

.review__rating h4 {
	font-family: Poppins;
	font-size: 34px;
	font-weight: 600;
	line-height: 41px;
	display: flex;
	align-items: center;
	color: #262626;
	margin-bottom: 8px;
}
.review__rating h4 span {
	color: #ffcf50;
	font-size: 25px;
	margin-right: 4px;
}
.review__rating p {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	color: #7d7d7d;
}
.review__progress {
	width: 350px;
}
.progress--fst span {
	font-family: Poppins;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	color: #262626;
}
.progress--fst span i {
	color: #ffcf50;
}
.prg {
	width: 250px;
	height: 8px;
	background: #ededed;
	border-radius: 10px;
	position: relative;
}
.progress--fst p {
	margin: 0;
	font-family: Poppins;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	color: #262626;
}
.progress--fst {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 12px;
}
.prg:before {
	position: absolute;
	width: 70%;
	background: #2c7d92;
	height: 100%;
	content: "";
	left: 0;
	border-radius: 51px;
}
.progress--fst.sec .prg::before {
	width: 28%;
}
.progress--fst.thir .prg::before {
	width: 11%;
}
.progress--fst.four .prg::before {
	display: none;
}
.progress--fst.five .prg::before {
	display: none;
}
.review__star--top--fx {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 40px;
}

.say__top h4 {
	font-size: 18px;
	line-height: 27px;
	font-weight: 700;
	color: #262626;
	margin: 0;
}
.say__top p {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	color: #262626;
	display: flex;
	align-items: center;
	margin: 0;
}
.say__top p span {
	width: 20px;
	height: 20px;
	background: #2c7d92;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 11px;
	color: #fff;
	border-radius: 23px;
	margin-left: 10px;
}
.say__top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 40px;
}
.say__img {
	width: 50px;
	height: 50px;
	margin-right: 10px;
}
.say__img img {
	width: 100%;
	height: 100%;
}
.say__ct h5 {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 700;
	line-height: 21px;
	color: #262626;
	margin-bottom: 3px;
}
.say__star span {
	font-size: 13px;
	color: #ffcf50;
}
.say__ri span {
	font-family: Poppins;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	color: #7d7d7d;
}
.say_lf-top {
	display: flex;
	align-items: center;
}
.say__fx {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 12px;
}
.say__text p {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	margin: 0;
	color: #5a5a60;
}
.say__single--box {
	padding-bottom: 25px;
	border-bottom: 1px solid #ededed;
	margin-bottom: 25px;
}
.say__single--box.say__last {
	padding-bottom: 0;
	margin-bottom: 0;
	border: 0;
}
.table__result.result--last {
	padding-bottom: 80px;
}
/*view--page--style--end*/

.place__single---input textarea {
	width: 68%;
	height: 160px;
	border: 1px solid #f2f2f2;
	border-radius: 25px;
	padding: 20px;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	color: #7d7d7d;
}
.place__single---input.add--place--wp {
	align-items: start;
}

.place__select .nice-select {
	width: 300px;
	display: flex;
	align-items: center;
}
.place__select .nice-select::after {
	border-width: 3px;
	height: 10px;
	width: 10px;
	right: 29px;
}
.place__single---input.palce__st {
	justify-content: start;
}

.select__tasl p {
	margin: 0;
	font-size: 14px;
	color: #7d7d7d;
	line-height: 21px;
}
.select__tasl {
	width: 100%;
	height: 85px;
	border: 2px solid #7d7d7d;
	border-style: dashed;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 20px;
	margin-bottom: 40px;
}

.plus__icon {
	width: 40px;
	height: 40px;
	background: #2c7d92;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	color: #fff;
}

.plus {
	width: 40px;
	height: 40px;
	background: #2c7d92;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	color: #fff;
}
.place__pls--fx {
	display: flex;
	align-items: center;
}

.upload__ig {
	width: 300px;
	height: 225px;
}
.upload__ig img {
	object-fit: cover;
	min-height: 100%;
	min-width: 100%;
	border-radius: 24px;
}
.place__upload--main.upload__main-wd {
	margin: auto;
	justify-content: center;
	margin-bottom: 30px;
}
.plus.del {
	background: #f15a5b;
}
a.plus.del {
	color: #ffffff;
}

.short__answer--wrap {
	width: 100%;
	background: #f7f8fa;
	padding: 30px;
	border-radius: 24px;
}
.short__answer--title h4 {
	font-family: Poppins;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 27px;
	color: #262626;
	margin: 0;
}
.short__answer--title h4 i {
	color: #2c7d92;
	margin-right: 5px;
}
.short__answer--title {
	margin-bottom: 24px;
}
.single--check.nurom {
	width: 450px;
	margin-left: auto;
}
.place__add-tx p {
	margin: 0;
	font-size: 12px;
	color: #7d7d7d;
	line-height: 18px;
}
.place__add-tx {
	max-width: 450px;
	margin-left: auto;
}
.place__add-tx p span {
	font-style: italic;
	color: #262626;
}
.place__single---input.short--check {
	margin-top: 32px;
	align-items: start;
}
.single--check.check-sec {
	width: 500px;
	margin-left: auto;
	margin-bottom: 80px;
}

.polling__label {
	width: 31%;
}
.polling__label label {
	font-family: Poppins;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	color: #262626;
}
.polling__imp--wp {
	width: 69%;
}
.polling__single--imp input {
	height: 50px;
	border: 1px solid #ededed;
	border-radius: 59px;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	padding: 0 24px;
	padding-right: 50px;
	width: 100%;
}
.polling__single--imp {
	position: relative;
	margin-bottom: 10px;
}
.polling__single--imp span {
	position: absolute;
	top: 50%;
	z-index: 999;
	right: 20px;
	transform: translateY(-50%);
}
.polling__inp {
	display: flex;
	align-items: start;
	justify-content: space-between;
}
.polling__single--imp.polling---task a {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 700;
	line-height: 21px;
	color: #2c7d92;
	text-decoration: none;
	display: flex;
	align-items: center;
}
.polling__single--imp.polling---task a i {
	margin-right: 5px;
}

.check__single--tx p {
	font-size: 14px;
	line-height: 21px;
	font-weight: 400;
	padding-left: 20px;
	position: relative;
	margin: 0;
	color: #5a5a60;
}
.check__single--tx p:before {
	position: absolute;
	width: 5px;
	height: 5px;
	background: #ffcf50;
	content: "";
	border-radius: 50%;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
}
.check__single--tx p span {
	font-weight: 700;
	color: #5a5a60;
}

.place__single---input.short__fx label {
	width: auto;
}
.single--check.short__ck {
	width: auto;
}
.short__fx {
	width: 350px;
	background: #fff;
	height: 50px;
	padding: 0 20px;
	border-radius: 20px;
	margin: 0 8px;
}
.check__single--tx.check-position p::before {
	top: 10px;
	transform: unset;
}

.scan__help p {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	margin: 0;
	color: #262626;
	margin-right: 5px;
}
.scan__help a {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 700;
	line-height: 21px;
	color: #2c7d92;
	text-decoration: none;
}
.scan__help a i {
	margin-right: 5px;
}
.scan__help {
	display: flex;
	align-items: center;
}
.scan__fx {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 26px;
}
.scan__fx .short__answer--title {
	margin-bottom: 0;
}

.augmented .info-drop-box {
	max-width: 320px;
	/* width:100%; */
	/* margin: auto; */
	/* margin-bottom: 25px; */
}

.augmented .place__single---input {
	align-items: start;
}

.augmented__inp input {
	max-width: 320px;
	width: 100%;
	height: 50px;
	border: 1px solid #ededed;
	padding: 0 20px;
	border-radius: 39px;
	margin: auto;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	color: #7d7d7d;
}
.augmented__inp {
	display: flex;
	justify-content: center;
}

.augmented.up--wd {
	width: 480px;
	display: flex;
	justify-content: start;
}
.augmented.up--wd .upload {
	width: 100%;
}
.modal-content > button[data-bs-dismiss="modal"] {
	position: absolute;
	top: 23px;
	right: 23px;
	color: #2c7d92 !important;
	z-index: 999;
	border: 0;
	background: transparent;
	font-size: 25px;
}

/*view--test--page--style--start*/

.test__wrap {
	width: 100%;
	padding: 24px;
	border: 1px solid #f1f1f1;
	border-radius: 24px;
	margin-bottom: 32px;
	background-color: #f7f8fa;
}

.test__top h4 {
	margin: 0;
	font-family: Poppins;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	color: #262626;
}

.test__top h4 img {
	margin-right: 5px;
}

.test__top a {
	width: 24px;
	height: 24px;
	background: #2c7d92;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	color: #fff;
	font-size: 12px;
	border-radius: 50%;
}
.test__top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 24px;
}
.test__btm p {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	color: #5a5a60;
	margin-bottom: 24px;
}

.test__bottom--imp input {
	width: 78%;
	height: 45px;
	background: #ededed;
	border: 0;
	border-radius: 32px;
	padding: 0 25px;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	color: #7d7d7d;
}

.test__bottom--imp button {
	width: 19%;
	height: 45px;
	border: 1px solid #2c7d92;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #2c7d92;
	border-radius: 52px;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 700;
	line-height: 21px;
	color: #ffffff;
}

.test__bottom--imp button i {
	margin-right: 7px;
}

.test__bottom--imp {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.test__top[aria-expanded="false"] {
	margin-bottom: 0;
}

.test__top[aria-expanded="true"] a i {
	transform: rotate(180deg);
	transition: 0.3s;
}

.test__top[aria-expanded="true"] a {
	background: #c2c2c2;
}

.test__top[aria-expanded="false"] a i {
	transform: rotate(0deg);
	transition: 0.3s;
}

.reject__wrap {
	width: 100%;
	padding: 25px;
	background: #feefef;
	border-radius: 24px;
}
.reject__top h5 {
	font-family: Poppins;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	margin: 0;
	color: #f15a5b;
	margin-bottom: 16px;
}
.reject__tx p {
	font-size: 14px;
	color: #262626;
	line-height: 34px;
	font-weight: 400;
	color: #262626;
	margin: 0;
}
.reject__tx p span {
	position: relative;
	padding-left: 20px;
	font-weight: 700;
}
.reject__tx p span:before {
	position: absolute;
	width: 5px;
	height: 5px;
	background: #262626;
	content: "";
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	border-radius: 50%;
}
.reject__top h5 img {
	margin-right: 10px;
}
.edt {
	background: transparent !important;
	border: 1px solid #2c7d92 !important;
	color: #2c7d92 !important;
}
/*view--test--page--style-end*/

/*view--page--style--start*/

/* dashbord-page-end */

/* =====================sohan--style--start======================== */

/*addplace--popup--style--start*/

.addplace__popup--btn {
	width: 300px;
	height: 60px;
	margin: auto;
	margin-top: 20px;
	margin-bottom: 50px;
}
.addplace__popup--btn a {
	width: 100%;
	height: 100%;
	background: red;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	color: #fff;
	font-size: 30px;
	border-radius: 5px;
}

.modal-dialog {
	max-width: 800px;
}
.modal-body {
	padding: 0;
}
.modal-content {
	border-radius: 16px;
}

.place__top {
	height: 70px;
	display: flex;
	align-items: center;
	padding-left: 24px;
	border-bottom: 1px solid #f2f2f2;
}
.place__top h4 {
	margin: 0;
	font-family: Poppins;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 27px;
	color: #262626;
}
.place__upload--main label {
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
	margin: 0;
	display: block;
	color: #262626;
	width: 30%;
}
/* .upload { */
/*! width: 320px; */
/*! height: 240px; */
/*! border: 1px solid #2C7D92; */
/*! border-style: dashed; */
/*! border-radius: 16px; */
/*! display: flex; */
/*! flex-direction: column; */
/*! align-items: center; */
/*! justify-content: center; */
/*! background: #F7F8FA; */
/* } */
.place__upload--main {
	width: auto;
	display: flex;
	align-items: start;
	/* justify-content: space-between; */
	margin-bottom: 30px;
}

.place__upload--main-form {
	width: 0px;
	display: flex;
	align-items: start;
	/* justify-content: space-between; */
	margin-bottom: 30px;
}
.place__upload--box {
	padding: 0 40px;
	padding-top: 40px;
}
.upload img {
	max-width: 48px;
	margin-bottom: 24px;
}
.upload p {
	font-size: 14px;
	line-height: 21px;
	color: #262626;
	margin-bottom: 8px;
	text-align: center;
}
.upload span {
	font-family: Poppins;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	color: #7d7d7d;
}
.upload p span {
	font-weight: 700;
	color: #2c7d92;
}

.place__single---input label {
	font-family: Poppins;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	color: #262626;
	width: 30%;
}
.place__single---input label span {
	color: #f15a5b;
}
.place__single---input input {
	width: 69%;
	height: 50px;
	border: 1px solid #ededed;
	border-radius: 59px;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	padding: 0 24px;
}
.place__single---input {
	margin-bottom: 32px;
}

.place__single---input {
	display: flex;
	align-items: center;
	/* justify-content: space-between; */
}

.single--check label {
	width: 500px;
	color: #262626;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 21px;
	display: flex;
	align-items: center;
}
.single--check containers {
	margin: 0;
}
.single--check .containers {
	margin: 0;
}
.single--check img {
	margin-left: 5px;
}

.place__single---input.place-wd input {
	width: 100%;
	/* margin-left: 27px; */
}
.place__single---input.place-wd input.input-radius {
	width: 100%;
	padding-right: 50px;
	/* margin-left: 27px; */
}
.place__single---input.place-wd input.input-point {
	width: 100%;
	padding-right: 60px;
	/* margin-left: 27px; */
}
.place__single---input.place-wd {
	justify-content: start;
}
#test {
	width: 100%;
	height: 450px;
}

.place__single---input.place-wd .single-input {
	width: 20%;
	position: relative;
	margin-bottom: 0;
}
/* .place__single---input.place-wd .single-input span { */
/* right: -2px; */
/* } */
.place__map {
	margin-bottom: 40px;
}

.map__btn {
	display: flex;
	align-items: center;
	justify-content: end;
	padding-bottom: 40px;
}
.location--single {
	margin-right: 10px;
}
.location--single input {
	width: 100%;
	height: 34px;
	border: 0;
	text-align: left;
	padding: 0;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 21px;
	text-align: left;
	padding: 0 20px;
}

/*addplace--popup--style--end*/

/*radio-button--style-start*/
.containers {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.containers input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
	visibility: hidden;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 22px;
	width: 22px;
	background-color: #ededed;
	border-radius: 5px;
	top: 50%;
	transform: translateY(-50%);
}

/* When the checkbox is checked, add a blue background */
.containers input:checked ~ .checkmark {
	background-color: #2c7d92;
}
.containers.disabled input:checked ~ .checkmark {
	background-color: #dfdfdf;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.containers input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.containers .checkmark:after {
	left: 9px;
	top: 5px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}
/*radio-button--style-end*/

/*view--page--style--start*/

.view__header__lf--bar {
	width: 15.63%;
	height: 100vh;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 99;
	background: #f7f8fa;
}

.view__page--body {
	width: 84.37%;
	margin-left: 15.63%;
}

.view__banner img {
	width: 100%;
}

.view__banner {
	margin-bottom: 0;
}

.view__logo {
	padding: 24px 0;
	text-align: center;
	border-bottom: 1px solid #efeff0;
}

.view__lf--bar-menu ul li a {
	width: 252px;
	height: 55px;
	display: flex;
	align-items: center;
	margin: auto;
	font-family: Poppins;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	text-decoration: none;
	color: #7d7d7d;
	transition: 0.3s;
	margin: 0;
	padding: 0 20px;
	border-radius: 60px;
}

.view__lf--bar-menu li a:hover {
	background: #e3ecf0;
	color: #2c7d92;
	font-weight: 700;
}

.sign__btn {
	margin-top: 294px;
}

.view__lf--bar-menu {
	padding-left: 20px;
}

.view-link {
	width: 170px;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: transparent;
	border: 0;
	font-family: Poppins;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	color: #262626;
	position: relative;
}

.user__ri {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 700;
	line-height: 21px;
	margin-bottom: 8px;
	color: #5a5a60;
	background: #ffffff;
}

.user_ri--single-box {
	width: 100%;
	height: 130px;
	background: #f7f8fa;
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: 0 24px;
	border-radius: 20px;
	margin-bottom: 16px;
}

.user__single--select .nice-select {
	background: #ffffff;
}



.intro__img {
	width: 300px;
}
.intro__img img {
	width: 100%;
	height: 100%;
}
.intro__content {
	width: 400px;
	margin-left: 50px;
}
.intro__content p {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	color: #5a5a60;
	margin-bottom: 32px;
}
.intro__single--blog h5 {
	font-family: Poppins;
	font-size: 18px;
	font-weight: 700;
	line-height: 27px;
	margin-bottom: 8px;
}
.intro__single--blog p {
	margin-bottom: 0;
}
.intro__fx {
	display: flex;
	align-items: start;
	padding-bottom: 50px;
	border-bottom: 1px solid #ededed;
	margin-bottom: 40px;
}
.intro__single--blog {
	margin-bottom: 30px;
}

.place__top--title h4 {
	font-size: 18px;
	line-height: 27px;
	color: #262626;
	margin: 0;
	font-weight: 700;
}
.place__menu ul li {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 700;
	line-height: 21px;
	display: flex;
	align-items: center;
	text-decoration: none;
	color: #7d7d7d;
}
.place__menu ul li img {
	margin-right: 7px;
}
.place__menu ul li {
	/* margin-left: 25px; */
	display: inline-block;
}
.place__top__task {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 40px;
}
.place__box {
	border: 1px solid #ededed;
	padding: 0;
	border-radius: 15px;
	background: #f7f8fa;
	display: flex;
	align-items: start;
	justify-content: space-between;
	min-height: 302px;
	margin-bottom: 30px;
}
.place__lf {
	width: 32%;
	padding: 24px;
	min-height: 302px;
}
.place__img img {
	width: 100%;
	margin-bottom: 16px;
}
.place__img--title h4 {
	font-family: Poppins;
	font-size: 18px;
	font-weight: 700;
	line-height: 27px;
	color: #262626;
}
.place__ri {
	width: 66%;
	background: #ffffff;
	padding: 24px 30px;
	min-height: 330px;
	border-radius: 20px 15px 15px 20px;
}
.place__cal span {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 700;
	line-height: 21px;
	display: flex;
	align-items: center;
	text-decoration: none;
	color: #7d7d7d;
}
.place__cal span i {
	margin-right: 8px;
}
.place__cal {
	margin-bottom: 20px;
}
.place__pond--contnt h5 {
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 21px;
	letter-spacing: 0em;
	text-align: left;
	margin-bottom: 4px;
	color: #262626;
}
.place__pond--contnt p {
	margin: 0;
	font-family: Poppins;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	color: #7d7d7d;
}
.place__details a {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 700;
	line-height: 21px;
	color: #2c7d92;
	text-decoration: none;
}
.place__pond__single--box {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 70px;
	width: 100%;
	padding: 0 20px;
	border-radius: 16px;
	cursor: pointer;
}
.place__pond__single--box:hover {
	background: #eaf2f4;
}
.place__pond__single--box.place__bg {
	background: #fff;
	transition: 0.3s;
}
.place__wuay--tx p {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	color: #7d7d7d;
	margin-bottom: 24px;
}
.place__wuay--tx span {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 700;
	line-height: 21px;
	display: flex;
	align-items: center;
	text-decoration: none;
	color: #7d7d7d;
	margin-bottom: 20px;
}
.place__wuay--tx span i {
	margin-right: 8px;
}
.chupit__box {
	width: 100%;
	border: 1px solid #f2f2f2;
	border-radius: 16px;
	padding: 20px;
	margin-bottom: 20px;
}
.chupit__img {
	width: 100px;
	height: 100px;
	margin-right: 20px;
}
.chupit__img img {
	width: 100%;
	height: 100%;
}
.chupit__content h5 {
	font-family: Poppins;
	font-size: 18px;
	font-weight: 700;
	line-height: 27px;
	color: #262626;
	margin-bottom: 4px;
}
.chupit__content p {
	font-family: Poppins;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	color: #7d7d7d;
}
.chupit__icon span {
	width: 25px;
	height: 25px;
	background: #c2c2c2;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 19px;
	color: #fff;
	font-size: 12px;
}
.chupit__top[aria-expanded="false"] .chupit__icon span {
	transform: rotate(180deg);
	background: #007f90;
}
.chupit__top--lf {
	display: flex;
	align-items: center;
}
.chupit__top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
}
.chupuit__task ul li {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 700;
	line-height: 21px;
	display: flex;
	align-items: center;
	text-decoration: none;
	color: #7d7d7d;
}
.chupuit__task ul li img {
	margin-right: 8px;
}
.place__box.place__box--sec {
	height: auto;
}

.place__img--title p {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 700;
	line-height: 21px;
	color: #7d7d7d;
	margin-bottom: 18px;
}
.place__img--title p span {
	font-weight: 400;
}
.place__img--title p i {
	margin-right: 10px;
}
.chupuit__task {
	margin-bottom: 20px;
}

.place__table tr th {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 700;
	line-height: 21px;
	background: #f7f8fa;
	border-bottom: 0;
	padding: 15px 0;
	vertical-align: middle;
}
.place__table tr td img {
	max-width: 40px;
	margin-right: 10px;
}
.place__table tr th:first-child {
	padding-left: 20px;
	border-radius: 16px 0 0px 16px;
}
.place__table tr th:last-child {
	border-radius: 0 16px 16px 0;
	text-align: center;
}
.place__table tr th img {
	margin-left: 5px;
}
.place__table tr td {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	color: #262626;
	vertical-align: middle;
	border-bottom: 0;
}

.view-link:before {
	position: absolute;
	width: 0;
	height: 2px;
	background: #2c7d92;
	content: "";
	left: 0;
	bottom: -1px;
	transition: 0.3s;
}
.view-link.active::before {
	width: 100%;
}
.view-link.active {
	color: #2c7d92;
	font-weight: 700;
}
.place__table tr td:last-child {
	text-align: center;
}
.qst-progress {
	width: 180px;
	height: 8px;
	background: #ededed;
	position: relative;
}
.qst-progress:before {
	position: absolute;
	width: 20%;
	height: 100%;
	background: #2c7d92;
	content: "";
	left: 0;
	top: 0;
	border-radius: 24px;
}
.progress__ct span {
	font-family: Poppins;
	font-size: 12px;
	font-weight: 700;
	line-height: 16px;
	color: #5a5a60;
}

.progress__fx {
	display: flex;
	align-items: center;
}
.progress__ct {
	margin-left: 25px;
}

.result_lf p {
	margin: 0;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
}
.result__number ul li {
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 700;
	line-height: 21px;
	justify-content: center;
	border-radius: 5px;
}
.result__number ul {
	display: flex;
	align-items: center;
}
.result__number ul li:nth-child(2) {
	background: #eaf2f4;
}

.table__result {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 40px;
}

.review__rating h4 {
	font-family: Poppins;
	font-size: 34px;
	font-weight: 600;
	line-height: 41px;
	display: flex;
	align-items: center;
	color: #262626;
	margin-bottom: 8px;
}
.review__rating h4 span {
	color: #ffcf50;
	font-size: 25px;
	margin-right: 4px;
}
.review__rating p {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	color: #7d7d7d;
}
.review__progress {
	width: 350px;
}
.progress--fst span {
	font-family: Poppins;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	color: #262626;
}
.progress--fst span i {
	color: #ffcf50;
}
.prg {
	width: 250px;
	height: 8px;
	background: #ededed;
	border-radius: 10px;
	position: relative;
}
.progress--fst p {
	margin: 0;
	font-family: Poppins;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	color: #262626;
}
.progress--fst {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 12px;
}
.prg:before {
	position: absolute;
	width: 70%;
	background: #2c7d92;
	height: 100%;
	content: "";
	left: 0;
	border-radius: 51px;
}
.progress--fst.sec .prg::before {
	width: 28%;
}
.progress--fst.thir .prg::before {
	width: 11%;
}
.progress--fst.four .prg::before {
	display: none;
}
.progress--fst.five .prg::before {
	display: none;
}
.review__star--top--fx {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 40px;
}

.say__top h4 {
	font-size: 18px;
	line-height: 27px;
	font-weight: 700;
	color: #262626;
	margin: 0;
}
.say__top p {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	color: #262626;
	display: flex;
	align-items: center;
	margin: 0;
}
.say__top p span {
	width: 20px;
	height: 20px;
	background: #2c7d92;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 11px;
	color: #fff;
	border-radius: 23px;
	margin-left: 10px;
}
.say__top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 40px;
}
.say__img {
	width: 50px;
	height: 50px;
	margin-right: 10px;
}
.say__img img {
	width: 100%;
	height: 100%;
}
.say__ct h5 {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 700;
	line-height: 21px;
	color: #262626;
	margin-bottom: 3px;
}
.say__star.star3 span:nth-child(5) {
	color: #ededed;
}
.say__star.star4 span:nth-child(4) {
	color: #ededed;
}
.say__star.star4 span:nth-child(5) {
	color: #ededed;
}
.say__star span {
	font-size: 13px;
	color: #ffcf50;
}
.say__ri span {
	font-family: Poppins;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	color: #7d7d7d;
}
.say_lf-top {
	display: flex;
	align-items: center;
}
.say__fx {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 12px;
}
.say__text p {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	margin: 0;
	color: #5a5a60;
}
.say__single--box {
	padding-bottom: 25px;
	border-bottom: 1px solid #ededed;
	margin-bottom: 25px;
}
.say__single--box.say__last {
	padding-bottom: 0;
	margin-bottom: 0;
	border: 0;
}
.table__result.result--last {
	padding-bottom: 80px;
}
/*view--page--style--end*/

.place__single---input textarea {
	width: 69%;
	height: 160px;
	border: 1px solid #f2f2f2;
	border-radius: 25px;
	padding: 20px;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	color: #7d7d7d;
}
.place__single---input.add--place--wp {
	align-items: start;
}

.place__select .nice-select {
	width: 300px;
	display: flex;
	align-items: center;
}
.place__select .nice-select::after {
	border-width: 3px;
	height: 10px;
	width: 10px;
	right: 29px;
}
.place__single---input.palce__st {
	justify-content: start;
}

.select__tasl p {
	margin: 0;
	font-size: 14px;
	color: #7d7d7d;
	line-height: 21px;
}
.select__tasl {
	width: 100%;
	height: 85px;
	border: 2px solid #7d7d7d;
	border-style: dashed;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 20px;
	margin-bottom: 40px;
}

.plus {
	width: 40px;
	height: 40px;
	background: #2c7d92;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	color: #fff;
}

.upload__ig {
	width: 300px;
	height: 225px;
}
.upload__ig img {
	object-fit: cover;
	min-height: 100%;
	min-width: 100%;
	border-radius: 24px;
}

.upload__ig-banner {
	width: 300px;
	height: 225px;
}
.upload__ig-banner img {
	object-fit: cover;
	width: 300px;
	height: 225px;
	border-radius: 24px;
}

.upload__ig-full {
	width: 225px;
	height: 300px;
}
.upload__ig-full img {
	object-fit: cover;
	width: 225px;
	height: 300px;
	border-radius: 24px;
}
.place__upload--main.upload__main-wd {
	margin: auto;
	justify-content: center;
	margin-bottom: 30px;
}
.plus.del {
	background: #f15a5b;
}

.short__answer--wrap {
	width: 100%;
	background: #f7f8fa;
	padding: 30px;
	border-radius: 24px;
}
.short__answer--title h4 {
	font-family: Poppins;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 27px;
	color: #262626;
	margin: 0;
}
.short__answer--title h4 i {
	color: #2c7d92;
	margin-right: 5px;
}
.short__answer--title {
	margin-bottom: 24px;
}
.single--check.nurom {
	width: 450px;
	margin-left: auto;
}
.place__add-tx p {
	margin: 0;
	font-size: 12px;
	color: #7d7d7d;
	line-height: 18px;
}
.place__add-tx {
	max-width: 450px;
	margin-left: auto;
}
.place__add-tx p span {
	font-style: italic;
	color: #262626;
}
.place__single---input.short--check {
	margin-top: 32px;
	align-items: start;
}
.single--check.check-sec {
	width: 500px;
	margin-left: auto;
	margin-bottom: 80px;
}

.polling__label {
	width: 31%;
}
.polling__label label {
	font-family: Poppins;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	color: #262626;
}
.polling__imp--wp {
	width: 69%;
}
.polling__single--imp input {
	height: 50px;
	border: 1px solid #ededed;
	border-radius: 59px;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	padding: 0 24px;
	padding-right: 50px;
	width: 100%;
}
.polling__single--imp {
	position: relative;
	margin-bottom: 10px;
}
.polling__single--imp span {
	position: absolute;
	top: 50%;
	z-index: 999;
	right: 20px;
	transform: translateY(-50%);
}
.polling__inp {
	display: flex;
	align-items: start;
	justify-content: space-between;
}
.polling__single--imp.polling---task a {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 700;
	line-height: 21px;
	color: #2c7d92;
	text-decoration: none;
	display: flex;
	align-items: center;
}
.polling__single--imp.polling---task a i {
	margin-right: 5px;
}

.check__single--tx p {
	font-size: 14px;
	line-height: 21px;
	font-weight: 400;
	padding-left: 20px;
	position: relative;
	margin: 0;
	color: #5a5a60;
}
.check__single--tx p:before {
	position: absolute;
	width: 5px;
	height: 5px;
	background: #ffcf50;
	content: "";
	border-radius: 50%;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
}
.check__single--tx p span {
	font-weight: 700;
	color: #5a5a60;
}

.place__single---input.short__fx label {
	width: auto;
}
.single--check.short__ck {
	width: auto;
}
.short__fx {
	width: 350px;
	background: #fff;
	height: 50px;
	padding: 0 20px;
	border-radius: 20px;
	margin: 7px 8px;
}
.check__single--tx.check-position p::before {
	top: 10px;
	transform: unset;
}

.scan__help p {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	margin: 0;
	color: #262626;
	margin-right: 5px;
}
.scan__help a {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 700;
	line-height: 21px;
	color: #2c7d92;
	text-decoration: none;
}
.scan__help a i {
	margin-right: 5px;
}
.scan__help {
	display: flex;
	align-items: center;
}
.scan__fx {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 26px;
}
.scan__fx .short__answer--title {
	margin-bottom: 0;
}

.augmented.up--wd {
	width: 480px;
	display: flex;
	justify-content: start;
}
.augmented.up--wd .upload {
	width: 100%;
}
.modal-content > button[data-bs-dismiss="modal"] {
	position: absolute;
	top: 15px;
	right: 23px;
	color: #2c7d92 !important;
	z-index: 999;
	border: 0;
	background: transparent;
	font-size: 25px;
}

/*view--test--page--style--start*/

.test__top a {
	width: 24px;
	height: 24px;
	background: #2c7d92;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	color: #fff;
	font-size: 12px;
	border-radius: 50%;
}
.test__top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 24px;
}
.test__btm p {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	color: #5a5a60;
	margin-bottom: 24px;
}

.reject__wrap {
	width: 100%;
	padding: 25px;
	background: #feefef;
	border-radius: 24px;
}
.reject__top h5 {
	font-family: Poppins;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	margin: 0;
	color: #f15a5b;
	margin-bottom: 16px;
}
.reject__tx p {
	font-size: 14px;
	color: #262626;
	line-height: 34px;
	font-weight: 400;
	color: #262626;
	margin: 0;
}
.reject__tx p span {
	position: relative;
	padding-left: 20px;
	font-weight: 700;
}
.reject__tx p span:before {
	position: absolute;
	width: 5px;
	height: 5px;
	background: #262626;
	content: "";
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	border-radius: 50%;
}
.reject__top h5 img {
	margin-right: 10px;
}
.edt {
	background: transparent !important;
	border: 1px solid #2c7d92 !important;
	color: #2c7d92 !important;
}
/*view--test--page--style-end*/

/*view--page--style--start*/

.view__task__top--img img {
	width: 100%;
}
.view__task__top--img {
	margin-bottom: 16px;
}
.view__task__sm--img {
	width: 120px;
	height: 120px;
	margin-right: 20px;
	border-radius: 20px;
	overflow: hidden;
	display: flex;
	justify-content: center;
	position: relative;
}
.view__task__sm--img .overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(130, 130, 130, 0.5);
	color: #fff;
	display: flex;
	justify-content: end;
	align-items: center;
	flex-direction: column;
	cursor: pointer;
	padding: 15px;
	opacity: 1;
}
.view__task__sm--img .overlay:hover {
	background-color: #2c7d92;
}
.view__task__sm--img .overlay .btn-play {
	margin-bottom: 10px;
}
.view__task__sm--img img {
	object-fit: cover;
	height: 100%;
	min-width: 100%;
	max-width: none;
}
.view__task__btm__sm {
	display: flex;
	align-items: center;
	margin-bottom: 32px;
}
.view__task__ck ul li {
	margin-right: 44px;
	display: flex;
	align-items: center;
	font-family: Poppins;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 27px;
	color: #262626;
}
.view__task__ck ul li img {
	margin-right: 8px;
}
.view__task__ck ul {
	display: flex;
	align-items: center;
}
.view__task__ck {
	margin-bottom: 32px;
}
.view__task--text p {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	color: #5a5a60;
	margin-bottom: 32px;
}
.short__ans h4,
.view-task-type h4 {
	font-family: Poppins;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 27px;
	color: #262626;
	margin-bottom: 20px;
}
.short__ans h4 i {
	margin-right: 8px;
	color: #2c7d92;
}

.short__ans--single--blog span,
.view-task-type-detail {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	display: block;
	color: #5a5a60;
	margin-bottom: 4px;
}
.short__ans--single--blog p {
	margin: 0;
	font-family: Poppins;
	font-size: 18px;
	font-weight: 400;
	line-height: 27px;
	color: #262626;
}
.short__ans--single--blog {
	margin-bottom: 20px;
}
.hints__wrap h4 {
	font-family: Poppins;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 27px;
	color: #262626;
	margin-bottom: 20px;
}
.hints_single--item {
	position: relative;
	padding: 30px 0;
	text-align: center;
	background: #f7f8fa;
	border-radius: 20px;
	margin-bottom: 16px;
}
.hints_single--item p {
	margin: 0;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 21px;
	color: #262626;
}
.hints_single--item span {
	position: absolute;
	font-family: Poppins;
	font-size: 40px;
	font-weight: 700;
	line-height: 50px;
	color: #e2e3e5;
	top: 8px;
	left: 24px;
}
.hints_single--item.hints--thr p span {
	position: unset;
	display: block;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 21px;
	color: #262626;
}
.hints_single--item.hints--thr {
	background: #fff1cb;
}
.reward__wp {
	width: 100%;
	background: #eaf2f4;
	padding: 13px;
	border-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-right: 30px;
}

.reward__ct p {
	margin: 0;
	font-family: Poppins;
	font-size: 18px;
	line-height: 27px;
	color: #262626;
}
.reward__search span {
	color: #2c7d92;
}
.rewards__lf {
	display: flex;
	align-items: center;
}
.reward__ct {
	margin-left: 16px;
}

.task__list {
	width: 100%;
	background: #f7f8fa;
	border-radius: 16px;
	padding: 24px;
}
.task__list h4 {
	font-size: 18px;
	color: #262626;
	line-height: 27px;
	margin: 0;
	font-weight: 700;
}
.task__list p {
	font-size: 14px;
	color: #7d7d7d;
	margin-bottom: 24px;
	font-weight: normal;
}
.task__number span {
	width: 25px;
	height: 25px;
	border: 2px solid #2c7d92;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	font-size: 12px;
	font-weight: 500;
	position: relative;
}
.task__number.number-sec span::after {
	width: 1px;
	background: #2c7d92;
	height: 48px;
	content: "";
	position: absolute;
	bottom: -47px;
}
.task__list__ct h4 {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 600;
	line-height: 21px;
	margin-bottom: 4px;
	color: #262626;
}
.task__list--single--item.active h4 {
	color: #007f90;
}
.task__list__ct p {
	margin: 0;
	font-size: 14px;
	color: #7d7d7d;
	margin-bottom: 24px;
	margin: 0;
	font-weight: normal;
}
.task__list--single--item {
	display: flex;
	align-items: center;
	margin-bottom: 24px;
}
.task__list--single--item.task__thr {
	margin: 0;
}
.task__list__ct {
	margin-left: 16px;
}
.task__number.number-sec span {
	background: #2c7d92;
	color: #fff;
	position: relative;
}
.task__number.number-sec span:before {
	position: absolute;
	width: 1px;
	height: 48px;
	background: #2c7d92;
	content: "";
	left: 50%;
	transform: translateX(-50%);
	top: -46px;
}
.task__list--single--item.task__thr {
	margin: 0;
}
.hints__wrap.re--sec {
	padding-bottom: 80px;
}

.add__hint--label {
	width: 31%;
}
.add__hint--label label {
	font-family: Poppins;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	color: #262626;
}
.add__hint__inner {
	width: 69%;
}
.add__hint__fx {
	display: flex;
	align-items: start;
	margin-top: 32px;
}
.add__hint--arrow span {
	width: 25px;
	height: 25px;
	background: #2c7d92;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	color: #fff;
	font-size: 12px;
}
.add__hint--top {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.add__hint--top .single--check {
	width: auto;
}

.add__hint--top .single--check label {
	width: auto;
}
.add__hint--top {
	margin-bottom: 25px;
}
.add__hint--single--inp input {
	width: 100%;
	height: 50px;
	border: 1px solid #ededed;
	border-radius: 59px;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	padding: 0 24px;
	margin-bottom: 16px;
}

.add__hint--top[aria-expanded="true"] .add__hint--arrow span i {
	transform: rotate(180deg);
	transition: 0.3s;
}
.add__hint--top[aria-expanded="false"] .add__hint--arrow span i {
	transform: rotate(0deg);
	transition: 0.3s;
}
.add__hint--top[aria-expanded="true"] .add__hint--arrow span {
	background: #c2c2c2;
}

.up--wp a {
	width: 160px;
	height: 40px;
	border: 1px solid #2c7d92;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50px;
	text-decoration: none;
	color: #2c7d92;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 700;
	line-height: 21px;
}
.up--wp a i {
	margin-right: 5px;
}
.up--wp span {
	font-family: Poppins;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	color: #7d7d7d;
	margin-left: 10px;
}
.up--wp {
	display: flex;
	align-items: center;
	padding-bottom: 80px;
}
.place__pls--fx.last {
	margin-bottom: 80px;
}
/*view--page--style--end*/
.header___bar a {
	font-size: 20px;
	color: #000;
}
.header___bar {
	display: none;
}
.view__header__lf--bar.active {
	left: 0;
}
.overlay {
	width: 100%;
	height: 100%;
	background: #27282a;
	position: fixed;
	left: -100%;
	top: 0;
	transition: 0.3s;
	opacity: 0.7;
	z-index: 9;
}
.overlay.active {
	left: 0;
}
.view__header__menu ul {
	margin: 0;
	padding: 0;
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

a {
	text-decoration: none;
	appearance: none !important;
}

.dash-link {
	width: 170px;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: transparent;
	border: 0;
	font-family: Poppins;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	color: #262626;
	position: relative;
	cursor: pointer;
}
.dash-link:hover {
	color: inherit;
}

.dash-link:before {
	position: absolute;
	width: 0;
	height: 2px;
	background: #2c7d92;
	content: "";
	bottom: 0;
	left: 0;
	transition: 0.3s;
}
.dash-link.active {
	color: #2c7d92;
	font-weight: 700;
}
.dash-link.active::before {
	width: 100%;
}
.dash-link span {
	width: 25px;
	height: 25px;
	background: #ffcf50;
	display: flex;
	justify-content: center;
	border-radius: 50%;
	font-size: 12px;
	color: #000;
	font-weight: 700;
	margin-left: 10px;
}

/*======================= style--end =========================*/

.drop-zone__input {
	display: none;
}

.drop-zone__thumb {
	width: 100%;
	height: 100%;
	border-radius: 10px;
	overflow: hidden;
	background-color: #cccccc;
	background-size: cover;
	position: relative;
}

.drop-zone__thumb::after {
	content: attr(data-label);
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 5px 0;
	color: #ffffff;
	background: rgba(0, 0, 0, 0.75);
	font-size: 14px;
	text-align: center;
}

.chupit__top[aria-expanded="true"] .chupit__icon {
	transform: rotate(-0deg);
}
.chupit__icon {
	transform: rotate(-180deg);
	transition: 0.3s;
}
.chupit__top[aria-expanded="true"] .chupit__icon span {
	transform: rotate(-180deg);
}

.place__upload--main label span {
	color: #f15a5b;
}
.polling__label label span {
	color: #f15a5b;
}
.n-review span {
	color: #ededed;
	background: #ededed;
}

input {
	/* background: #F7F8FA; */
	transition: 0.3s;
}
input:focus {
	/* background: transparent; */
	color: #000;
	opacity: 1;
}

textarea {
	/* background: #F7F8FA; */
	transition: 0.3s;
}
textarea:focus {
	background: transparent;
	opacity: 1;
	color: #000;
	outline: none;
}

.bg-table tr:hover {
	background: #e8f2f4;
}
.table-box tr:hover td {
	background: #e8f2f4;
}

.table-box tr td a {
	color: #000;
}

.table-box tr:hover td a {
	color: #2c7d92;
}

.dark-green {
	color: #2c7d92 !important;
}
.red-1 {
	color: #f15a5b !important;
}

.edit-po-area {
	height: 400px;
}
.single-edit {
	margin-bottom: 20px;
	display: flex;
	align-items: center;
}
.edit-bar {
	margin-right: 25px;
}
.edit-bar a {
	color: #7d7d7d;
}
.edit-text h3 {
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	color: #262626;
	margin-bottom: 3px;
}
.edit-text p {
	font-size: 14px;
	line-height: 21px;
	color: #7d7d7d;
	margin: 0;
}

.botanic__button.disabled-btn .btn {
	opacity: 0.5;
	pointer-events: none;
	background: #ededed;
	color: #c2c2c2;
	border-color: #efefef;
}
.botanic__button.disabled-btn .btn:nth-child(2) {
	background: transparent;
	width: 120px;
}
.botanic__button.disabled-btn span {
	color: #c2c2c2;
}
.bg-none {
	background-color: transparent;
	border: none;
}

.info-single-check label p span {
	width: 24px;
	height: 24px;
	font-weight: 700;
	font-size: 13px;
	line-height: 24px;
	color: #262626;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	margin-right: 15px;
	position: absolute;
	left: 0;
	background: #c2c2c2;
}

.place-up-box {
	background: #f7f8fa;
	border-radius: 24px;
	padding: 30px 35px;
	margin-bottom: 30px;
	display: none;
}
.up-box-top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
}
.up-box-top a {
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 27px;
	color: #262626;
}
.up-box-top a i {
	color: #2c7d92;
	margin-right: 10px;
}
.up-box-top-right {
	display: flex;
	align-items: center;
}
.up-box-top-right span {
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	color: #262626;
	margin-right: 10px;
}
.up-box-top-right a {
	font-weight: 700;
	font-size: 14px;
	line-height: 21px;
	text-align: center;
	color: #2c7d92;
}
.up-box p {
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	color: #262626;
	margin-bottom: 20px;
}
.up-box-fl {
	display: flex;
	justify-content: space-between;
}
.up-box-left {
	width: 71%;
}
.up-box-left a {
	margin-bottom: 30px;
	display: block;
}

.chen {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 10px;
}
.chen a {
	font-weight: 700;
	font-size: 14px;
	line-height: 21px;
	text-align: center;
	color: #2c7d92;
	margin: 0;
}
.chen a img {
	margin-right: 10px;
}
.chen a:last-child {
	color: #f15a5b;
}
.up-right {
	width: 26%;
}
.up-right-title h3 {
	font-weight: 700;
	font-size: 14px;
	line-height: 21px;
	color: #262626;
	display: flex;
	align-items: center;
	margin: 0;
}
.up-right-title {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
}
.single-row-box h3 {
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	color: #262626;
}
.single-row-box h3 i {
	margin-right: 10px;
	color: #2c7d92;
	font-size: 19px;
}
.single-row-box {
	margin-bottom: 24px;
}
.single-row-box:last-child {
	margin: 0;
}
.nice-number {
	position: relative;
	background: #ffffff;
	border: 1px solid #ededed;
	box-sizing: border-box;
	border-radius: 24px;
	height: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.nice-number button {
	position: absolute;
	left: 9px;
	top: 50%;
	transform: translateY(-50%);
	width: 19.5px;
	height: 19.5px;
	background: #2c7d92;
	border-radius: 50%;
	color: #fff !important;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
}
.nice-number input {
	border: 0;
	background: #fff;
}
.nice-number button:last-child {
	left: auto;
	right: 10px;
}

.info-title.q5 {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
}
.info-title.q5 a {
	width: 160px;
	height: 50px;
	left: 473px;
	top: 50px;
	background: #2c7d92;
	border-radius: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
}

.btn-ctn {
	display: flex;
}
.btn-ctn a {
	margin: 0 14px;
}
.transparent-btn {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: #2c7d92 !important;
	background: transparent !important;
}
.transparent-btn img {
	padding-left: 8px;
}
.info-title.q5 a img {
	padding-right: 6px;
}

.track-area.q5 .track-left,
.track-area.qt5 .track-left {
	position: relative;
	min-height: 361px;
}
.track-area.q5 .bat-title,
.bat-title.qt5 {
	position: absolute;
	bottom: 19px;
	left: 0;
	right: 0;
	text-align: center;
	width: 80%;
	margin: auto;
}

.hdn-palce {
	text-align: center;
	margin-bottom: 60px;
}
.hdn-palce > a {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: #2c7d92;
	background: #ffffff;
	border: 1px solid #2c7d92;
	box-sizing: border-box;
	border-radius: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 230px;
	height: 50px;
	margin: 16px auto;
}

.track-area.q5.hpa .track-left {
	background: #fff8e5;
	border-radius: 22px;
}

.place__upload--main label {
	margin: 0;
}

.task-covers {
	margin-left: 30%;
}

.place__pls--fx .plus,
.place__pls--fx .del {
	margin-left: 20px;
}

.spot-it-input {
	background-color: #ffffff;
	border: 1px solid #ededed;
	border-radius: 25px;
	position: relative;
}

.spot-it-input .left-img,
.spot-it-input .right-img {
	width: auto;
	height: 20px;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}
.spot-it-input .left-img {
	left: 12px;
}
.spot-it-input .right-img {
	right: 12px;
}

.spot-it-input input {
	border: 1px solid #ededed;
	border-radius: 25px;
	width: 100%;
	text-align: center;
	background-color: #ffffff;
	padding-right: 40px;
	padding-left: 40px;
}

.spot-it-input input:active {
	border: 1px solid #ededed;
	border-radius: 25px;
	background-color: #ffffff;
}

.map__btn .btn-nav {
	width: 160px;
	height: 50px;
	border: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 54px;
	font-family: Poppins;
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	color: #f15a5b;
	text-decoration: none;
}

.btn-nav.cancel {
	border: 1px solid #f15a5b;
	background-color: #ffffff;
}

.btn-nav.save {
	border: 0;
	background: #2c7d92;
	color: #fff;
	margin-left: 50px;
}
